import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { hmrBootstrap } from 'hmr';

if ( environment.production )
{
    enableProdMode();
}

export function getApiUrl() {
    return 'https://europe-west1-prosa-exprit.cloudfunctions.net/api/api/v1.0';
}

const providers = [
    { provide: 'API_URL', useFactory: getApiUrl, deps: [] },
];
      

const bootstrap = () => platformBrowserDynamic(providers).bootstrapModule(AppModule);

if ( environment.hmr )
{
    if ( module['hot'] )
    {
        hmrBootstrap(module, bootstrap);
    }
    else
    {
        console.error('HMR is not enabled for webpack-dev-server!');
        console.log('Are you using the --hmr flag for ng serve?');
    }
}
else
{
    bootstrap().catch(err => console.error(err));
}
