export const PROSA_ERR_FIREBASE_AUTH = 'auth.error.firebase-unknown';
export const PROSA_ERR_UNKNOWN = 'app.error.unknown';

export type ProsaErrorOptions =
  'actionCode' |
  'message' |
  'actionUrl' |
  'icon' |
  'error';

export enum ProsaErrorCode {
  GENERIC = 'app.error.unknown',
  OFFLINE = 'app.error.no-connection',
  SERVICE = 'app.error.no-service',
  DEVICE = 'app.error.no-space',
  CREDENTIALS = 'auth.error.firebase-auth/user-not-found',
  PASSWORD = 'auth.error.firebase-auth/wrong-password',
}

export type ProsaErrorOptionsObject = { [option in ProsaErrorOptions]?: any };

export class ProsaError extends Error {
  constructor(public code: ProsaErrorCode | string, public options: ProsaErrorOptionsObject = {}) {
    super(options.message ? options.message : code);
    // see: typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    this.name = ProsaError.name; // stack traces display correctly now
  }
}
