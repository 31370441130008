import { FirebaseAppConfig } from '@angular/fire';

export const firebaseConfig: FirebaseAppConfig = {
  apiKey: "AIzaSyAV7kkwdJC7ZWc60qx4xhHlBQtAcrtasFM",
  authDomain: "prosa-exprit.firebaseapp.com",
  databaseURL: "https://prosa-exprit.firebaseio.com",
  projectId: "prosa-exprit",
  storageBucket: "prosa-exprit.appspot.com",
  messagingSenderId: "416930487009",
  appId: "1:416930487009:web:63b92874e5d986931bb5a0",
  measurementId: "G-7R3Y57PX8S"
};
