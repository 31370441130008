import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'prosa-error-popover',
  templateUrl: './error-popover.component.html',
  styleUrls: ['./error-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProsaErrorPopoverComponent {

  constructor(
    public dialogRef: MatDialogRef<ProsaErrorPopoverComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data) {      
    }


  onActionClick() {
    this.dialogRef.close();
  }

} // -
