/*
 *
 */

export interface SearchRequest<T> {
  query: string;
  attributesSearch: Array<keyof T>;
}

export interface SearchResponse<T> {
  hits: SearchResultEntry<T>[];
  query: string;
}

export interface SearchResultEntry<T> {
  record: T;
  match: {
    property: keyof T;
    offset: number;
    length: number;
  };
}


export const MAX_SEARCH_STRING = 50;

/**
 * This method has api similar to "algolia"
 * https://www.algolia.com/doc/api-reference/api-methods/search/?language=javascript
 *
 * @more: https://firebase.google.com/docs/firestore/solutions/search
 */
export function arrayFullTextSearch<T>(data: T[], parameters: SearchRequest<T>): SearchResponse<T> {
  const queryString = prepareString(parameters.query || '');
  const searchFields = parameters.attributesSearch || [];
  const minQueryLength = 3;

  const result = {
    hits: [],
    query: parameters.query
  };

  if (queryString.length < minQueryLength || searchFields.length === 0) {
    return result;
  }

  const queryRegEx = createRegexp(queryString);
  console.log('RegEx created: %s ->', queryString, queryRegEx);

  for (let irecord = 0; irecord < data.length; irecord++) {
    for (let ifield = 0; ifield < searchFields.length; ifield++) {
      const val = data[irecord][searchFields[ifield]];

      if (!val || typeof val !== 'string') {
        continue;
      }

      const m = val.match(queryRegEx);
      if (m) {
        result.hits.push({
          record: data[irecord],
          match: {
            property: searchFields[ifield],
            offset: m.index,
            length: m[0].length,
          }
        });
        break; // move to the next record
      }
    }
  }
  return result;
}

// prepare string for searching
function prepareString(str: string): string {
  return str.toLowerCase().trim()
    .replace(/\s+/g, ' '); // replace multiple spaces with single one
}


// prepare string for searching
function createRegexp(str: string): RegExp {
  str = str.substr(0, MAX_SEARCH_STRING);
  const templateString = prepareString(str)
    // .replace(/\s+/g, ' ')       // replace multiple spaces with single one
    .replace(/[^\s\w\']/g, '')    // remove everything except characters
    .replace(/ /g, '\\s+')         // replace whitespace with regex token
  ;
  return new RegExp(templateString, 'i');
}

