export const locale = {
  lang: 'en',
  data: {
    NAV: {
      HOME: 'Home',
      FUNCTIONS: 'Functions',
      SWOT_LIST: 'List of SWOT',
      PROCESS_LIST: 'List of processes',
    }
  }
};
