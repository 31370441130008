import { Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationErrorService } from './services/validation-error.service';

export abstract class ProsaControl {
    @Input() control = new FormControl();
    @Input() placeholder?: string;

    get error(): string | null {
        return this.validationErrorService.getControlError(this.control);
    }

    /**
     * @param {ValidationErrorService} validationErrorService
     */
    constructor(
        private validationErrorService: ValidationErrorService
    ) { }

}
