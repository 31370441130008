import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { auth } from 'firebase';
import { ProsaError } from 'app/shared';

@Component({
  selector: 'prosa-change-password',
  templateUrl: './change-password.component.html',
})
export class ChangePasswordPopupComponent implements OnInit {
  formGroup: FormGroup;

  get isValid(): boolean {
    return this.formGroup.valid;
  }

  /**
   * Constructor
   *
   * @param {MatDialogRef<ChangePasswordPopupComponent>} dialogRef
   *
   */
  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ChangePasswordPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.formGroup = this._formBuilder.group({
        currentPassword: ['', Validators.required],
        newPassword: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      }, {
        validator: this._comparePasswords('newPassword', 'confirmPassword')
      });
    }

  ngOnInit(): void { }

  onClose(): void {
    this.dialogRef.close();
  }

  async onConfirm(): Promise<any> {
    try {
      const { currentPassword, newPassword } = this.formGroup.value;
      const credential = auth.EmailAuthProvider.credential(
        auth().currentUser.email, currentPassword );

      await auth().currentUser.reauthenticateWithCredential(credential)
        .then(() => {
          this._setCurrPassError(false);
          return this._changePassword(newPassword).then(this.onClose.bind(this));
        }, this._setCurrPassError.bind(this, true));
    } catch (error) {
      throw new ProsaError(error);
    }
  }

  private _changePassword(newPassword: string): Promise<any> {
    return auth().currentUser.updatePassword(newPassword);
  }

  private _setCurrPassError(state): void {
    this.formGroup.get('currentPassword').setErrors(
      state ? { 'password-invalid': true } : null
    );
  }

  private _comparePasswords(firstControlName: string, secondControlName: string): any {
    return (formGroup: FormGroup) => {
      const firstControl = formGroup.controls[firstControlName];
      const secondControl = formGroup.controls[secondControlName];

      if (secondControl.errors && !secondControl.errors['password-not-equal']) { return; }

      secondControl.setErrors(
        firstControl.value !== secondControl.value
          ? { 'password-not-equal': true } : null
      );
    };
  }
}
