export const locale = {
  lang: 'en',
  data: {
    // MODULE: FOOTER
    footerModule: {
      prices: 'Prices',
      privacy: 'Privacy',
      conditionOfUse: 'Conditions Of Use',
    },
  }
};
