import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SubscriptionService } from 'app/shared/services/subscription.service';

@Component({
  selector: 'prosa-print-report-popup',
  templateUrl: './print-report.component.html',
})
export class PrintReportPopupComponent implements OnInit {

  get isSecondOptionDisabled(): boolean {
    if (this._subscriptionService.isSilverSubscriber || this._subscriptionService.isTrialSubscriber) {
      return true
    }
  }

  get isThirdOptionDisabled(): boolean {
    if (this._subscriptionService.isSilverSubscriber || this._subscriptionService.isTrialSubscriber) {
      return true
    }
  }

  constructor(
    public dialogRef: MatDialogRef<PrintReportPopupComponent>,
    private _subscriptionService: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void { }

  onClose(payload: string): void {
    this.dialogRef.close(payload);
  }

  onSelect(option: string): void {
    let type: string;
    switch (option) {
      case 'first': type = 'synthetic'; break;
      case 'second': type = 'extended'; break;
      case 'third': type = 'migration-plan'; break;
      default: type = 'error';
    }
    this.onClose(type);
  }
}
