import { OperatorFunction } from 'rxjs';
import { DocumentChangeAction, Action, DocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

export function extractSnapshotDocumentsPipe<T>(): OperatorFunction<DocumentChangeAction<T>[], T[]> {
  return map((actions: DocumentChangeAction<T>[]) => {
    // .snapshotChanges() returns a DocumentChangeAction[], which contains
    // a lot of information about "what happened" with each change. If you want to
    // get the data and the id use the map operator.
    // https://github.com/angular/angularfire2/blob/7eb3e51022c7381dfc94ffb9e12555065f060639/docs/firestore/collections.md#example
    return actions.map(a => {
      const data = a.payload.doc.data() as Partial<T>;
      const id = a.payload.doc.id;
      return {id, ...data} as unknown as T;
    });
  });
}

/**
 * use after "get()" for collections
 */
export function extractQuerySnapshotPipe<T>(): OperatorFunction<QuerySnapshot<T>, T[]> {
  return map((querySnapshot: QuerySnapshot<T>) => {
    return querySnapshot.docs.map(snapshot => {
      const data = snapshot.data() as Partial<T>;
      const id = snapshot.id;
      const path = snapshot.ref.path;
      return {id, path, ...data} as unknown as T;
    });
  });
}

/**
 * use after "doc().snapshotChanges()"
 */
export function extractDocument<T>(): OperatorFunction<Action<DocumentSnapshot<T>>, T> {
  return map((snapshot: Action<DocumentSnapshot<T>>) => {
    const data = snapshot.payload.data() as Partial<T>;
    const $id = snapshot.payload.id;
    const $path = snapshot.payload.ref.path;
    return {$id, $path, ...data} as unknown as T;
  });
}
