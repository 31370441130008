import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
    MatTableModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatMenuModule,
    MatDatepickerModule,
    MatTabsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatSliderModule,
} from '@angular/material';
import { PortalModule } from '@angular/cdk/portal';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
    imports: [
        MatMomentDateModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatTreeModule,
        MatTableModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatMenuModule,
        MatDatepickerModule,
        MatTabsModule,
        MatExpansionModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        PortalModule,
        MatChipsModule,
        MatSliderModule,
        MatSidenavModule,
        MatSnackBarModule
    ],
    exports: [
        MatMomentDateModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatTreeModule,
        MatTableModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatMenuModule,
        MatDatepickerModule,
        MatTabsModule,
        MatExpansionModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        PortalModule,
        MatChipsModule,
        MatSliderModule,
        MatSidenavModule,
        MatSnackBarModule
    ]
})
export class MaterialModule { }
