import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap, tap, take } from 'rxjs/operators';

import * as firebase from 'firebase';
import EmailAuthProvider = firebase.auth.EmailAuthProvider;
import { ProsaRoleType, ProsaUserProfile, ProsaSubscriptionType } from '../interfaces';
import { ProsaError, PROSA_ERR_FIREBASE_AUTH } from '../error';
import { OnlineStatusService } from './online-status.service';
import { NavigationService } from 'app/services/navigation.service';


export enum ProsaAuthStatus {
  guest = '',
  authenticated = 'auth',
  authorized = 'authorized'
}

export const FIREBASE_AUTH_ERR__PREFIX = 'auth.error.firebase-';

export const FIREBASE_AUTH_ERR_NETOWORK = 'auth/network-request-failed';

const PROSA_AUTH_USER_ID = 'prosa.auth.userId';

@Injectable()
export class ProsaAuthService {
  public userManagedDistrict$: Observable<string>;

  authState$: Observable<any>;

  firebaseUser = new BehaviorSubject<firebase.User>(null);
  user$: BehaviorSubject<ProsaUserProfile>;
  userId$: BehaviorSubject<string>;
  authStatus$: BehaviorSubject<ProsaAuthStatus>;

  get currentUser(): ProsaUserProfile {
    return this.user$.getValue();
  }

  get userId(): string {
    return this.userId$.getValue();
  }

  get assignedUsers(): DocumentReference[] {
    const user = this.currentUser;
    if (user && user.assignedUsers) {
      const ass = user.assignedUsers;
      if (!ass.map(user => user.id).includes(this.userId)) ass.push(this.firebaseStore.doc('profiles/' + this.userId).ref);
      return user ? ass : null;
    } else return [];
  }

  get userRole(): ProsaRoleType {
    const user = this.currentUser;
    return user ? user.roleType : null;
  }

  get subscriptionType(): ProsaSubscriptionType {
    const user = this.currentUser;
    return user ? user.subscriptionType : null;
  }

  get authStatus(): string {
    return this.authStatus$.getValue();
  }

  get isAdminRole(): boolean {
    return this.userRole === ProsaRoleType.admin;
  }

  get isUserRole(): boolean {
    return this.userRole === ProsaRoleType.user;
  }

  get isGuestRole(): boolean {
    return !this.userRole || this.userRole === ProsaRoleType.guest;
  }

  get isPlatinum(): boolean {
    return this.subscriptionType === ProsaSubscriptionType.platinum;
  }

  get isOwned(): boolean {
    return this.currentUser.owner === undefined;
  }

  /**
   *
   */
  constructor(
    private firebaseStore: AngularFirestore,
    private firebaseAuth: AngularFireAuth,
    private translateService: TranslateService,
    private _onlineStatusService: OnlineStatusService,
    private _navigationService: NavigationService
  ) {
    this.user$ = new BehaviorSubject(null);
    this.userId$ = new BehaviorSubject(null);
    this.authStatus$ = new BehaviorSubject(null);

    this.loadSavedUserId();

    this.authState$ = this.firebaseAuth.authState.pipe(
      // Set user Id to localeStorage
      tap(this.setUserId.bind(this)),
      // Load user from db
      switchMap(fbUser => {
        // Update userId
        this.userId$.next(fbUser ? fbUser.uid : null);
        console.log(`ProsaAuthService.userId$=${this.userId}`);

        if (!fbUser) {
          // Update auth status
          this.authStatus$.next(ProsaAuthStatus.guest);
          return of(null);
        }

        return this.fetchUserByAuthId(fbUser.uid);
      }),
      tap((user: ProsaUserProfile) => {
        // Update auth status
        this.authStatus$.next(user ? ProsaAuthStatus.authorized : ProsaAuthStatus.authenticated);
        console.log(`ProsaAuthService.authStatus$=${this.authStatus}`);

        // Update user role
        if (user) {
          user.roleType = user.roleType || ProsaRoleType.guest;
        }

        // Update user
        this.user$.next(user);
        console.log(`ProsaAuthService.user$=`, user);

        // Set navigation
        this._navigationService.setNavigation(this.userRole);
      })
    );

    this.authState$.subscribe();
  }

  /**
   * Resolver
   */
  resolve(): Observable<any> | Promise<any> | any {
    return this.authState$;
  }

  public getAuthToken(): Promise<string> {
    return firebase.auth().currentUser.getIdToken();
  }

  /**
   *
   */
  public async emailSignUp(email: string, password: string): Promise<any> {
    try {
      const credentials = await this.firebaseAuth.auth.createUserWithEmailAndPassword(email, password);
      // await credentials.user.sendEmailVerification();
      this.setUserId(credentials.user);
    } catch (error) {
      this.processFirebaseError(error);
    }
  }

  /**
   *
   * @param path
   */
  public async deleteAtPath(path): Promise<void> {
    const deleteFn = firebase.app().functions('europe-west1').httpsCallable('recursiveDelete');

    try {
      const result = await deleteFn({ path: path });
      console.log('Delete success: ' + JSON.stringify(result));
    } catch (error) {
      console.log('Delete failed, see console,');
      console.warn(error);
    }
  }

  /**
   *
   */
  public restorePassword(email: string): Promise<any> {
    return this.firebaseAuth.auth.sendPasswordResetEmail(email)
      .catch(this.processFirebaseError.bind(this));
  }

  /**
   *
   */
  public async updatePassword(oldPassword: string, newPassword: string): Promise<any> {
    const user = await this.firebaseAuth.user.pipe(take(1)).toPromise();
    const credential = EmailAuthProvider.credential(user.email, oldPassword);

    await user.reauthenticateWithCredential(credential);
    return user.updatePassword(newPassword);
  }

  /**
   *
   */
  public async loginByEmail(email: string, password: string): Promise<any> {
    try {
      const credentials = await this.firebaseAuth.auth.signInWithEmailAndPassword(email, password);
      if (credentials.user.emailVerified) {
        this.setUserId(credentials.user);
        const user = await this.fetchUserByAuthId(credentials.user.uid).pipe(take(1)).toPromise();
        if (user.roleType && user.roleType !== ProsaRoleType.guest) {
          this._navigationService.navigateToHome();
        } else {
          throw new ProsaError('auth.error.firebase-auth/user-has-guest-role', { message: 'L\'utente ha il ruolo di ospite.' });
        }
      } else {
        this.firebaseUser.next(credentials.user);
        await this.firebaseAuth.auth.signOut();
        throw new ProsaError('auth.error.firebase-auth/email-not-verified',
          { message: 'Indirizzo email non verificato. Prego controllare la vostra casella di posta e confermare l aregistrazione' });
      }
    } catch (err) {
      this.handleFirebaseAuthErr(err);
    }
  }

  /**
   *
   */
  public logout(): Promise<void> {
    this.userId$.next(null);
    localStorage.removeItem(PROSA_AUTH_USER_ID);
    this._onlineStatusService.updateStatus(this._onlineStatusService.offlineStatus);
    return this.firebaseAuth.auth.signOut();
  }

  /**
   * private methods: start
   */

  /**
   *
   */
  protected fetchUserByAuthId(authId): Observable<ProsaUserProfile> {
    if (!authId) {
      return of<ProsaUserProfile>(null);
    }
    return this.firebaseStore.doc<ProsaUserProfile>(`profiles/${authId}`)
      .valueChanges()
      .pipe(switchMap(async user => {
        if (user.owner !== undefined) {
          const newUser = await this._getPlatinumAndGroup(user);
          user.assignedUsers = newUser.assignedUsers;
          return !!user ? { ...user, uid: authId } : null;
        } else
          return !!user ? { ...user, uid: authId } : null;
      }))
      .pipe(catchError(err => {
        console.error(err);
        return of(null);
      }));
  }

  private async _getPlatinumAndGroup(user: ProsaUserProfile): Promise<ProsaUserProfile> {
    const userOwner = (await this.firebaseStore.doc(user.owner.path).get().toPromise()).data() as ProsaUserProfile;
    userOwner.assignedUsers.push(user.owner);
    return userOwner;
  }

  /**
   *
   */
  protected processFirebaseError(err): never {
    const code = `auth.error.firebase-${err.code}`;
    const message = this.translateService.instant(code);
    throw new ProsaError(message ? code : PROSA_ERR_FIREBASE_AUTH, {
      message: message || err.message
    });
  }

  /**
   *
   */
  private async loadSavedUserId(): Promise<void> {
    this.userId$.next(localStorage.getItem(PROSA_AUTH_USER_ID));
  }

  /**
   *
   */
  private handleFirebaseAuthErr(err): never {
    // console.log('auth: handleFirebaseAuthErr', err);
    const code = `auth.error.firebase-${err.code}`;
    if (this.translateService.instant(code)) {
      let msg = err.message;
      if (code === 'auth.error.firebase-auth/wrong-password') {
        msg = 'Credenziali errate';
      }
      throw new ProsaError(code, { message: msg });
    } else {
      throw new ProsaError(PROSA_ERR_FIREBASE_AUTH, { message: err.message });
    }
  }

  /**
   *
   */
  private setUserId(user: firebase.User): void {
    if (user) {
      this.userId$.next(user.uid);
      localStorage.setItem(PROSA_AUTH_USER_ID, user.uid);
    } else {
      localStorage.removeItem(PROSA_AUTH_USER_ID);
    }
  }

}
