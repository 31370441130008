import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProsaErrorPopoverComponent, ProsaErrorMailNotVerifiedComponent } from '../components';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import {
  FIREBASE_AUTH_ERR__PREFIX,
  FIREBASE_AUTH_ERR_NETOWORK,
  ProsaError,
  ProsaErrorCode,
  ProsaErrorOptionsObject,
  ProsaAuthService
} from '../../shared';
import { MatDialog } from '@angular/material/dialog';

const TITLE_CODE_SUFFIX = '.title';

@Injectable()
export class ProsaErrorService {
  public readonly error$: Observable<ProsaError>;
  private errorSubject = new Subject<ProsaError>();
  //   private popover: HTMLIonModalElement;

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private authService: ProsaAuthService
  ) {
    this.error$ = this.errorSubject.pipe(debounceTime(100));
    this.error$.subscribe(this.showPopover.bind(this));
  }

  public emitErrorCode(errorCode: string, options: ProsaErrorOptionsObject = {}) {
    if (errorCode) {
      this.errorSubject.next(new ProsaError(errorCode, options));
    } else {
      // this will just hide the err
      this.errorSubject.next(null);
    }
  }

  public emitError(error: ProsaError) {
    this.errorSubject.next(error);
  }

  async showPopover(err: ProsaError | { rejection?: ProsaError } | any) {

    if (err.rejection) {
      // promise rejection
      err = err.rejection;
    }

    const errorCode = this.mapErrorCode(err.code);
    //const errTitle = this.getTitleCode(errorCode);
    const iconUrl = this.getIconPath(errorCode);

    // TODO. We need different cases for errors
    // Temporary: till implementation of different cases for errors
    const errTitle = err.code === 'prosa-coming-soon' ? 'Funzione presto in arrivo' :'Attenzione è stato commesso un errore';

    // console.log(err, errTitle, errorCode);

    if (!errorCode.includes('email-not-verified')) {
      this.dialog.open(ProsaErrorPopoverComponent, {
        panelClass: 'prosa-dialog-container',
        data: {
          errCode: errorCode,
          errTitle: errTitle,
          iconUrl: iconUrl,
          ...err.options
        }
      });
    } else {
      const dialogEmailNotVerified = this.dialog.open(ProsaErrorMailNotVerifiedComponent, {
        width: '500px',
        height: '300px',
        data: {
          idUser: this.authService.firebaseUser.value
        }
      });

      dialogEmailNotVerified.afterClosed().subscribe(response => {
        if (response === 0) {
          this.authService.firebaseUser.next(null);
        }
      });
    }
  }

  /**
   *   merge error codes from different services
   */
  protected mapErrorCode(errCode: string): string {
    switch (errCode) {
      case FIREBASE_AUTH_ERR__PREFIX + FIREBASE_AUTH_ERR_NETOWORK:
        return ProsaErrorCode.OFFLINE;
      default:
        return errCode || ProsaErrorCode.GENERIC;
    }
  }

  /**
   * @param errCode
   */
  protected getIconPath(errCode: string): string {
    switch (errCode) {
      case ProsaErrorCode.DEVICE:
        return 'assets/images/app/no-space.svg';
      case ProsaErrorCode.OFFLINE:
        return 'assets/images/app/error-offline.svg';
      case ProsaErrorCode.CREDENTIALS:
        return 'assets/images/app/credentials.svg';
      case ProsaErrorCode.SERVICE:
        return 'assets/images/app/error-01.svg';
      default:
        return 'assets/images/errors/basic.png';
    }
  }

  /**
   * check that error code has special title translation
   */
  protected getTitleCode(errCode: string): string {
    const titleCode = errCode + TITLE_CODE_SUFFIX;
    const translatedCode = this.translate.instant(titleCode);
    // this.translate.instant(titleCode)
    if (translatedCode !== titleCode) {
      return titleCode;
    } else {
      // no special translation for title. use common
      return ProsaErrorCode.GENERIC + TITLE_CODE_SUFFIX;
    }
  }
}
