import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProsaAuthService, ProsaError, ProsaUserProfile, UserProvider, ProsaRoleType } from 'app/shared';
import { tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { getApiUrl } from 'main';

@Injectable()
export class UsersListService implements Resolve<any> {
  usersLength: any;
  users: ProsaUserProfile[];
  onManagedDistrictsChanged: BehaviorSubject<any>;
  onUsersChanged: BehaviorSubject<any>;
  onUsersLengthChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {AuthService} _authService
   * @param {AngularFirestore} _firebaseStore
   */
  constructor(
    private _authService: ProsaAuthService,
    private _userProvider: UserProvider,
    private _http: HttpClient
  ) {
    // Set the defaults
    this.onUsersChanged = new BehaviorSubject({});
    this.onUsersLengthChanged = new BehaviorSubject(0);
  }

  /**
   * Resolver
   *
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getInitialLength().toPromise(),
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  getItemsLengthObs(): Observable<number> {
    return this.onUsersLengthChanged;
  }

  getLength(): Observable<ProsaUserProfile[]> {
    const authId = this._authService.userId;

    if (!authId) {
      throw new ProsaError('auth.auth-guard.error.not-authenticated');
    }

    return this._userProvider.getAll().pipe(
      tap((users: ProsaUserProfile[] = []) => {
        this.usersLength = users.filter(user => user.roleType === ProsaRoleType.user).length;
        this.onUsersLengthChanged.next(this.usersLength);
      })
    );
  }

  async verifyEmail(id: string): Promise<Observable<any>> {
    const token = await this._authService.getAuthToken();

    return this._http.post(getApiUrl() + '/verifyEmail', {
      id: id
    }, {
      headers: {
        'Authorization': 'Bearer ' + token
      }, responseType: 'text'
    });
  }

  getInitialLength(): Observable<ProsaUserProfile[]> {

    const authId = this._authService.userId;

    if (!authId) {
      throw new ProsaError('auth.auth-guard.error.not-authenticated');
    }

    return this._userProvider.getAll().pipe(
      tap((users: ProsaUserProfile[] = []) => {
        this.usersLength = users.filter(user => user.roleType === ProsaRoleType.user).length;
        this.onUsersLengthChanged.next(this.usersLength);
      })
    );
  }

  private _getFiltered(
    pageSize,
    pageIndex,
    filterValue
  ): Observable<ProsaUserProfile[]> {
    const authId = this._authService.userId;

    if (!authId) {
      throw new ProsaError('auth.auth-guard.error.not-authenticated');
    }

    return this._userProvider.getFiltered().pipe(
      map((users: ProsaUserProfile[]) => {
        // Filter all users by description field
        const filtered = filterValue ?
          users.filter((el: ProsaUserProfile) => {
            let filterForName = false;
            let filterForEnabled = false;
            let filterForVerify = false;

            if (filterValue['name']) filterForName = true;
            if (filterValue['verify']) filterForVerify = true;
            if (filterValue['disabled'] !== filterValue['enabled']) filterForEnabled = true;

            if (filterForName && filterForEnabled) {
              return (el.name.toLowerCase() + el.surname.toLowerCase()).includes(filterValue['name'].toLowerCase())
                && el.disabled === filterValue['disabled'] && el.subscriptionType === filterValue['subscriptionType'];
            }

            if (filterForEnabled) return el.disabled === filterValue['disabled'] && el.subscriptionType === filterValue['subscriptionType'];
            if (filterForVerify) return el.hasLoggedIn === !filterValue['verify'] && el.subscriptionType === filterValue['subscriptionType'];

            if (filterForName) {
              return (el.name.toLowerCase() + el.surname.toLowerCase()).includes(filterValue['name'].toLowerCase()) && el.subscriptionType === filterValue['subscriptionType'];
            }

            return el.subscriptionType === filterValue['subscriptionType'];

          }) : users;
        // Set length of filtered users
        this.usersLength = filtered.length;
        this.onUsersLengthChanged.next(filtered.length);

        // Calculate offset for filtered users and get filtered users chunk
        const startIndex = pageIndex * pageSize;
        return filtered.splice(startIndex, pageSize);
      })
    );
  }

  private _getInitial(pageSize, pageIndex): Observable<ProsaUserProfile[]> {
    return this._userProvider.loadInitialPage(pageSize).pipe(
      tap((data: []) => {
        this.users = data;
        this.onUsersChanged.next(data);
      })
    );
  }

  private _updatePage(pageSize, pageIndex): Observable<ProsaUserProfile[]> {
    return this._userProvider.loadUpdatedPage(pageSize).pipe(
      tap((data: ProsaUserProfile[]) => {
        this.users = data;
        this.onUsersChanged.next(data);
      })
    );
  }

  private _getNext(pageSize, pageIndex): Observable<ProsaUserProfile[]> {
    return this._userProvider.loadNextPage(pageSize).pipe(
      tap((data: ProsaUserProfile[]) => {
        this.users = data;
        this.onUsersChanged.next(data);
      })
    );
  }

  private _getPrevious(pageSize, pageIndex): Observable<ProsaUserProfile[]> {
    return this._userProvider.loadPreviousPage(pageSize).pipe(
      tap((data: ProsaUserProfile[]) => {
        this.users = data;
        this.onUsersChanged.next(data);
      })
    );
  }

  private _sort(): Observable<ProsaUserProfile[]> {
    return this.onUsersChanged;
  }

  resetPagination(pageSize: number): void {
    this._userProvider.resetPagination(pageSize);
  }

  getObsItems(
    isUpdatePage = false,
    pageSize = 10,
    pageIndex = 0,
    filterValue,
    sorting
  ): Observable<ProsaUserProfile[]> {
    const authId = this._authService.userId;

    if (!authId) {
      throw new ProsaError('auth.auth-guard.error.not-authenticated');
    }

    if (filterValue) {
      return this._getFiltered(pageSize, pageIndex, filterValue);
    }
    // When we update user and we want to stay on the same page
    if (isUpdatePage) {
      return this._updatePage(pageSize, pageIndex);
    }
    // When we have active sort and staying on the same page
    if (sorting.active && pageIndex === this._userProvider.paginationClickedCount) {
      return this._sort();
    }
    // When we change page size in select we reset pagination
    if (this._userProvider.pageSize !== pageSize) {
      this.resetPagination(pageSize);
    }
    // When we do not have last response that means that we load initial users
    if (!this._userProvider.lastInResponse) {
      return this._getInitial(pageSize, pageIndex);
    }
    // Determine if we need to load users for the next or for the previous page
    if (this._userProvider.paginationClickedCount > pageIndex) {
      return this._getPrevious(pageSize, pageIndex);
    } else {
      return this._getNext(pageSize, pageIndex);
    }
  }

  getUsersForPopUp() {
    this._userProvider.getAllUsersActive().subscribe(users => {
      this.users = users;
      this.onUsersChanged.next(users);
    });
  }
}
