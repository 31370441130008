import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference, AngularFirestoreCollection } from '@angular/fire/firestore';
import { extractSnapshotDocumentsPipe, extractDocument, extractQuerySnapshotPipe } from 'app/shared/utils';
import { take, catchError, tap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SWOT, ProsaError, ProsaAuthService } from 'app/shared';
import * as _ from 'lodash';

@Injectable()
export class SwotProvider {
  firstInResponse;
  lastInResponse;
  pageIndex = 0;
  pageSize = 10;
  paginationClickedCount = 0;
  prevStrtAt: any = [];

  /**
   * Constructor
   *
   * @param {AngularFirestore} _firebaseStore
   * @param {ProsaAuthService} _authService
   */
  constructor(
    private _firebaseStore: AngularFirestore,
    private _authService: ProsaAuthService
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Swots
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get swot by swotId
   *
   * @param {string} swotId
   *
   * @returns {Observable<SWOT>}
   */
  getSwot(swotId): Observable<SWOT> {
    return this._firebaseStore.doc(`swots/${swotId}`)
      .snapshotChanges()
      .pipe(
        extractDocument(),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      ) as Observable<SWOT>;
  }

  /**
   * Check if swot has voices
   *
   * @param {string} swotId
   *
   * @returns {boolean}
   */
  getSwotVoices(swotId): Promise<any> {
    return this._firebaseStore.doc(`swots/${swotId}`).collection('voices').get().toPromise().then(snap => snap.empty);
  }

  /**
   * Add swot
   *
   * @param {string} profileId
   * @param {Partial<SWOT>} swot
   *
   * @returns {Promise<DocumentReference>}
   */
  async addSwot(profileId: string, swot: Partial<SWOT>): Promise<DocumentReference> {
    try {
      const newSwot = {
        ..._.cloneDeep(swot),
        profileId: this._firebaseStore.doc(`profiles/${profileId}`).ref
      } as SWOT;

      const swotDocRef = await this._firebaseStore.collection(`swots`).add(newSwot);
      // HOTFIX
      this.resetPagination(this.pageSize);
      return swotDocRef;
    } catch (error) {
      throw new ProsaError(error);
    }
  }

  /**
   * Edit swot
   *
   * @param {Partial<SWOT>} swot
   *
   * @returns {Promise<void>}
   */
  async editSwot(swot: Partial<SWOT>): Promise<void> {
    try {
      const result = await this._firebaseStore.doc(`swots/${swot.id}`).update({
        creationDate: swot.creationDate,
        description: swot.description,
        sector: swot.sector,
        profileId: swot.profileId,
        auditUid: this._authService.userId
      });
      return result;
    } catch (error) {
      throw new ProsaError(error);
    }
  }

  /**
   * Delete swot
   *
   * @param {string} swotId
   *
   * @returns {Promise<void>}
   */
  async deleteSwot(swotId: string): Promise<void> {
    try {
      const swotDocRef = await this._authService.deleteAtPath(`swots/${swotId}`);
      // HOTFIX
      this.resetPagination(this.pageSize);
      return swotDocRef;
    } catch (error) {
      throw new ProsaError(error);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Swots pagination
  // -----------------------------------------------------------------------------------------------------

  /**
   * Push previous startAt document
   *
   * @param prevFirstDoc
   */
  private _pushPrevStartAt(prevFirstDoc): void {
    if (!prevFirstDoc) { return; }
    if (this.prevStrtAt.map(el => el.id).indexOf(prevFirstDoc.id) === -1) {
      this.prevStrtAt.push(prevFirstDoc);
    }
  }

  /**
   * Pop previous startAt document
   *
   * @param prevFirstDoc
   */
  private _popPrevStartAt(prevFirstDoc): void {
    this.prevStrtAt.forEach(element => {
      if (prevFirstDoc.id === element.id) {
        element = null;
      }
    });
  }

  /**
   * Get previous startAt document
   *
   */
  private _getPrevStartAt(): void {
    if (this.prevStrtAt.length > (this.paginationClickedCount + 1)) {
      this.prevStrtAt.splice(this.prevStrtAt.length - 2, this.prevStrtAt.length - 1);
    }
    return this.prevStrtAt[this.paginationClickedCount - 1];
  }

  /**
   * We use filter on client side. So we reset info about pagination before getting all swots
   *
   * @param {string} profileId
   */
  getFilteredSwots(profileId: string, allUsers: boolean, isPlatinum: boolean, ids: DocumentReference[]): Observable<SWOT[]> {
    this.lastInResponse = null;
    this.firstInResponse = null;
    this.paginationClickedCount = 0;
    this.prevStrtAt = [];

    return allUsers ? this.getAllUsersSwots() : isPlatinum ? this.getIsPlatinumSwots(ids) : this.getAllSwots(profileId);
  }

  /**
   * Get all swots for datasource table
   *
   * @param {string} profileId
   *
   * @returns {Observable<SWOT[]>}
   */
  getAllSwots(profileId): Observable<SWOT[]> {
    return this._firebaseStore.collection(`swots`, ref => ref
      .where('profileId', '==', this._firebaseStore.doc(`profiles/${profileId}`).ref)
      .orderBy('creationDate', 'asc')
    )
      .snapshotChanges()
      .pipe(
        take(1),
        extractSnapshotDocumentsPipe(),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      ) as Observable<SWOT[]>;
  }

  /**
   * Get swots of all users
   *
   * @returns {Observable<SWOT[]>}
   */
  getAllUsersSwots(): Observable<SWOT[]> {
    return this._firebaseStore.collection(`swots`, ref => ref
      .orderBy('creationDate', 'asc')
    )
      .snapshotChanges()
      .pipe(
        take(1),
        extractSnapshotDocumentsPipe(),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      ) as Observable<SWOT[]>;
  }

  getIsPlatinumSwots(ids: DocumentReference[]): Observable<SWOT[]> {
    return this._firebaseStore.collection(`swots`, ref => ref//.where('profileId', 'in', ids)
      .orderBy('creationDate', 'asc')
    )
      .snapshotChanges()
      .pipe(
        take(1),
        map(events => {
          return events.filter(ev => {
            const swto = ev.payload.doc.data() as SWOT;
            if (ids) {
              const idsR = ids.map(a => a.id);
              return idsR.includes(swto.profileId.id);
            } else return true;
          })
        }),
        extractSnapshotDocumentsPipe(),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      ) as Observable<SWOT[]>;
  }

  /**
   * Get all swots by current profileRef
   *
   * @returns {Observable<SWOT[]>}
   */
  getAllSwotsByCurrentRef(): Observable<SWOT[]> {
    const authId = this._authService.userId;

    if (!authId) {
      throw new ProsaError('auth.auth-guard.error.not-authenticated');
    }

    return this.getAllSwots(authId);
  }

  /**
   * Update swots page for datasource table
   *
   * @param {string} profileId
   * @param {number} pageSize
   *
   * @returns {Observable<SWOT[]>}
   */
  loadUpdatedSwotPage(profileId: string, pageSize: number, allUsers: boolean, isPlatinum: boolean, ids: DocumentReference[]): Observable<SWOT[]> {
    return (allUsers ?
      this._updatedAllUsersSwotPageCollection(pageSize) :
      isPlatinum ? this._updatedIsPLatinumSwotPageCollection(pageSize, ids) :
        this._updatedSwotPageCollection(profileId, pageSize))
      .get()
      .pipe(
        take(1),
        extractQuerySnapshotPipe(),
        map(events => events.filter(ev => {
          const swto = ev as SWOT;
          if (ids && isPlatinum) {
            const idsR = ids.map(a => a.id);
            return idsR.includes(swto.profileId.id);
          } else return true;
        })),
      ) as Observable<SWOT[]>;
  }

  private _updatedAllUsersSwotPageCollection(pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`swots`, ref => ref
      .limit(pageSize)
      .orderBy('creationDate', 'asc')
      .startAt(this.firstInResponse)
    );
  }

  private _updatedIsPLatinumSwotPageCollection(pageSize: number, ids: DocumentReference[]): AngularFirestoreCollection {
    if (ids.length < 10) {
      return this._firebaseStore.collection(`swots`, ref => ref.where('profileId', 'in', ids)
        .limit(pageSize)
        .orderBy('creationDate', 'asc')
        .startAt(this.firstInResponse)
      );
    } else {
      return this._firebaseStore.collection(`swots`, ref => ref  //.where('profileId', 'in', ids)
        //.limit(pageSize)
        .orderBy('creationDate', 'asc')
        .startAt(this.firstInResponse)
      );
    }
  }

  private _updatedSwotPageCollection(profileId: string, pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`swots`, ref => ref
      .where('profileId', '==', this._firebaseStore.doc(`profiles/${profileId}`).ref)
      .limit(pageSize)
      .orderBy('creationDate', 'asc')
      .startAt(this.firstInResponse)
    );
  }

  /**
   * Get initial swots for datasource table
   *
   * @param {string} profileId
   * @param {number} pageSize
   *
   * @returns {Observable<SWOT[]>}
   */
  loadInitialSwotPage(profileId: string, pageSize: number, allUsers: boolean, isPlatinum: boolean, ids: DocumentReference[]): Observable<SWOT[]> {
    this.lastInResponse = null;
    this.firstInResponse = null;
    this.paginationClickedCount = 0;
    this.prevStrtAt = [];
    this.pageIndex = 0;
    return (allUsers ?
      this._initialAllUsersSwotPageCollection(pageSize) :
      isPlatinum ? this._initialIsPLatinumSwotPageCollection(pageSize, ids) :
        this._initialSwotPageCollection(profileId, pageSize))
      .snapshotChanges()
      .pipe(
        take(1),
        map(events => {
          return events.filter(ev => {
            const swto = ev.payload.doc.data() as SWOT;
            if (ids && isPlatinum) {
              const idsR = ids.map(a => a.id);
              return idsR.includes(swto.profileId.id);
            } else return true;
          })
        }),
        tap(actions => {
          this.firstInResponse = actions[0] ? actions[0].payload.doc : null;
          this.lastInResponse = actions[actions.length - 1] ? actions[actions.length - 1].payload.doc : null;
          this.prevStrtAt = [];
          this.paginationClickedCount = 0;
          // Push first item to use for Previous action
          this._pushPrevStartAt(this.firstInResponse);
        }),
        extractSnapshotDocumentsPipe(),
        catchError(err => {
          console.error(err);
          return of([]);
        }),
      ) as Observable<SWOT[]>;
  }

  private _initialAllUsersSwotPageCollection(pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`swots`, ref => ref
      .orderBy('creationDate', 'asc')
      .limit(pageSize)
    );
  }

  private _initialIsPLatinumSwotPageCollection(pageSize: number, ids: DocumentReference[]): AngularFirestoreCollection {
    if (ids.length < 10) {
      return this._firebaseStore.collection(`swots`, ref => ref.where('profileId', 'in', ids)
        .orderBy('creationDate', 'asc')
        .limit(pageSize)
      );
    } else {
      return this._firebaseStore.collection(`swots`, ref => ref //.where('profileId', 'in', ids)
        .orderBy('creationDate', 'asc')
        //.limit(pageSize)
      );
    }
  }

  private _initialSwotPageCollection(profileId: string, pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`swots`, ref => ref
      .where('profileId', '==', this._firebaseStore.doc(`profiles/${profileId}`).ref)
      .orderBy('creationDate', 'asc')
      .limit(pageSize)
    );
  }

  /**
   * Get next swots for datasource table
   *
   * @param {string} profileId
   * @param {number} pageSize
   *
   * @returns {Observable<SWOT[]>}
   */
  loadNextSwotPage(profileId: string, pageSize: number, allUsers: boolean, isPlatinum: boolean, ids: DocumentReference[]): Observable<SWOT[]> {
    return (allUsers ?
      this._nextAllUsersSwotPageCollection(pageSize) :
      isPlatinum ? this._nextIsPlatinumSwotPageCollection(pageSize, ids) :
        this._nextSwotPageCollection(profileId, pageSize))
      .snapshotChanges()
      .pipe(
        take(1),
        map(events => {
          return events.filter(ev => {
            const swto = ev.payload.doc.data() as SWOT;
            if (ids && isPlatinum) {
              const idsR = ids.map(a => a.id);
              return idsR.includes(swto.profileId.id);
            } else return true;
          })
        }),
        tap(actions => {
          this.firstInResponse = actions[0].payload.doc;
          this.lastInResponse = actions[actions.length - 1].payload.doc;
          this.paginationClickedCount++;
          this._pushPrevStartAt(this.firstInResponse);
        }),
        extractSnapshotDocumentsPipe()
      ) as Observable<SWOT[]>;
  }

  private _nextAllUsersSwotPageCollection(pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`swots`, ref => ref
      .limit(pageSize)
      .orderBy('creationDate', 'asc')
      .startAfter(this.lastInResponse)
    );
  }

  private _nextIsPlatinumSwotPageCollection(pageSize: number, ids: DocumentReference[]): AngularFirestoreCollection {
    if (ids.length < 10) {
      return this._firebaseStore.collection(`swots`, ref => ref.where('prodileId', 'in', ids)
        .limit(pageSize)
        .orderBy('creationDate', 'asc')
        .startAfter(this.lastInResponse)
      );
    } else {
      return this._firebaseStore.collection(`swots`, ref => ref //.where('prodileId', 'in', ids)
        //.limit(pageSize)
        .orderBy('creationDate', 'asc')
        .startAfter(this.lastInResponse)
      );
    }
  }

  private _nextSwotPageCollection(profileId: string, pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`swots`, ref => ref
      .where('profileId', '==', this._firebaseStore.doc(`profiles/${profileId}`).ref)
      .limit(pageSize)
      .orderBy('creationDate', 'asc')
      .startAfter(this.lastInResponse)
    );
  }

  /**
   * Get previous swots for datasource table
   *
   * @param {string} profileId
   * @param {number} pageSize
   *
   * @returns {Observable<SWOT[]>}
   */
  loadPreviousSwotPage(profileId: string, pageSize: number, allUsers: boolean, isPlatinum: boolean, ids: DocumentReference[]): Observable<SWOT[]> {
    return (allUsers ?
      this._previousAllUsersSwotPageCollection(pageSize) :
      isPlatinum ? this._previousIsPLatinumSwotPageCollection(pageSize, ids) :
        this._previousSwotPageCollection(profileId, pageSize))
      .snapshotChanges()
      .pipe(
        take(1),
        map(events => {
          return events.filter(ev => {
            const swto = ev.payload.doc.data() as SWOT;
            if (ids && isPlatinum) {
              const idsR = ids.map(a => a.id);
              return idsR.includes(swto.profileId.id);
            } else return true;
          })
        }),
        tap(actions => {
          this.firstInResponse = actions[0].payload.doc;
          this.lastInResponse = actions[actions.length - 1].payload.doc;
          this.paginationClickedCount--;
          // Pop not required value in array
          this._popPrevStartAt(this.firstInResponse);
        }),
        extractSnapshotDocumentsPipe()
      ) as Observable<SWOT[]>;
  }

  private _previousAllUsersSwotPageCollection(pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`swots`, ref => ref
      .orderBy('creationDate', 'asc')
      .startAt(this._getPrevStartAt())
      .endBefore(this.firstInResponse)
      .limit(pageSize)
    );
  }

  private _previousIsPLatinumSwotPageCollection(pageSize: number, ids: DocumentReference[]): AngularFirestoreCollection {
    if (ids.length < 10) {
      return this._firebaseStore.collection(`swots`, ref => ref.where('profileId', 'in', ids)
        .orderBy('creationDate', 'asc')
        .startAt(this._getPrevStartAt())
        .endBefore(this.firstInResponse)
        .limit(pageSize)
      );
    } else {
      return this._firebaseStore.collection(`swots`, ref => ref //.where('profileId', 'in', ids)
        .orderBy('creationDate', 'asc')
        .startAt(this._getPrevStartAt())
        .endBefore(this.firstInResponse)
        //.limit(pageSize)
      );
    }
  }

  private _previousSwotPageCollection(profileId: string, pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`swots`, ref => ref
      .where('profileId', '==', this._firebaseStore.doc(`profiles/${profileId}`).ref)
      .orderBy('creationDate', 'asc')
      .startAt(this._getPrevStartAt())
      .endBefore(this.firstInResponse)
      .limit(pageSize)
    );
  }

  /**
   * Reset pagination
   *
   * @param {number} pageSize
   */
  resetPagination(pageSize: number): void {
    this.lastInResponse = null;
    this.firstInResponse = null;
    this.pageSize = pageSize;
    this.paginationClickedCount = 0;
    this.prevStrtAt = [];
  }
}
