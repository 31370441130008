import { FuseNavigation } from '@fuse/types';

export const getNavigation = (role: string = 'admin'): FuseNavigation[] => [
  {
    id: 'home',
    title: 'HOME',
    translate: 'NAV.HOME',
    type: 'item',
    url: `/home`,
    svgIcon: 'borders'
  },
  {
    id: 'functions',
    title: 'Functions',
    translate: 'NAV.FUNCTIONS',
    type: 'group',
    children: [
      {
        id: 'swot list',
        title: 'swot list',
        translate: 'NAV.SWOT_LIST',
        type: 'item',
        url: `/swots`,
        svgIcon: 'redistribution',
      },
      {
        id: 'menu swot',
        title: '- MENU SWOT -',
        type: 'group',
        hidden: true,
        classes: 'prosa-collapsable-group-title',
      },
      {
        id: 'process list',
        title: 'process list',
        translate: 'NAV.PROCESS_LIST',
        type: 'item',
        url: `/processes`,
        svgIcon: 'organization'
      }
    ]
  },
];
