import { Component, OnInit, Input } from '@angular/core';
import { ProsaControl } from '../../prosa.control';

@Component({
  selector: 'prosa-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent extends ProsaControl implements OnInit {
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;
  @Input() color: string;

  ngOnInit(): void { }
}
