export const locale = {
  lang: 'it',
  data: {
    // MODULE: TOOLBAR
    toolbarModule: {
      trial: {
        trial: 'LICENZA TRIAL:',
        trialMessage: 'il tuo periodo di prova scade il',
        linkToPrices: 'Consulta i prezzi e l\' abbonamento adatto a te.',
      },
      payUser: {
        subscription: 'Il tuo abbonamento',
        aboutToExpire: 'scade il',
        expired: 'è scaduto il',
        renew: 'Rinnova il tuo abbonamento'
      },
      userMenu: {
        editProile: 'Modifica profilo',
        changePassword: 'Cambia password',
        signOut: 'Esci',
      },
    },
  }
};
