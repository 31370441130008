import * as firebase from 'firebase';  // Don't remove this line
import Timestamp = firebase.firestore.Timestamp;


// fix toDate() to return same object for same date
const toDateOriginal = Timestamp.prototype.toDate;
Timestamp.prototype.toDate = function () {
  const self = this as Timestamp & {_date: Date };
  const date = toDateOriginal.call(this);
  if (!self._date || self._date.getTime() !== date.getTime()) {
    self._date = date;
  }
  return self._date;
};


/**
 * firebase timestamp without annoying stuff
 * @deprecated use Firebase.ts interface
 */
export {Timestamp};
