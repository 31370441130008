import { Injectable } from '@angular/core';

// SERVICES
import { ProsaAuthService } from './auth.service';
import { subscriptionTypes } from '../interfaces';

@Injectable()
export class SubscriptionService {

  get isPlatinumSubscriber(): boolean {
    return this._authService.currentUser && this._authService.currentUser.subscriptionType === subscriptionTypes.platinum;
  }

  get isGoldSubscriber(): boolean {
    return this._authService.currentUser && this._authService.currentUser.subscriptionType === subscriptionTypes.gold;
  }

  get isSilverSubscriber(): boolean {
    return this._authService.currentUser && this._authService.currentUser.subscriptionType === subscriptionTypes.silver;
  }

  get isTrialSubscriber(): boolean {
    return this._authService.currentUser && this._authService.currentUser.subscriptionType === subscriptionTypes.trial;
  }

  get isAdmin(): boolean {
    return this._authService.isAdminRole;
  }

  get isOwned(): boolean {
    return this._authService.isOwned;
  }

  constructor(
    private _authService: ProsaAuthService,
  ) { }
}
