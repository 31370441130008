import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { ContentComponent } from 'app/layout/components/content/content.component';
import { ContentPathComponent } from './content-path/content-path.component';
import { MaterialModule } from 'app/modules/material/material.module';
import { ContentPathService } from './content-path/content-path.service';

@NgModule({
    declarations: [
        ContentComponent,
        ContentPathComponent
    ],
    imports     : [
        RouterModule,
        FuseSharedModule,
        MaterialModule
    ],
    exports     : [
        ContentComponent
    ],
    providers: [
        ContentPathService
    ],
})
export class ContentModule
{
}
