import { Component, ViewEncapsulation } from '@angular/core';
import { ContentPathService } from './content-path/content-path.service';

@Component({
    selector     : 'content',
    templateUrl  : './content.component.html',
    styleUrls    : ['./content.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentComponent
{
    /**
     * Constructor
     */
    constructor(
        private _contentPathService: ContentPathService
    ) { }

    isContentPathHidden(): boolean {
        return this._contentPathService.isHidden();
    }
}
