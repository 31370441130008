import { DocumentReference } from '@angular/fire/firestore';
import { Timestamp } from './Timestamp';

export interface SWOT {
  $id?: string;
  $path?: string;
  id?: string;
  auditUid?: string;
  profileId: DocumentReference;
  creationDate: Timestamp;
  description: string;
  sector: string;
  percComplete: number;
  percValMit: number;
  percPositiveValue: number;
  strengthsVal: number;
  opportunitiesVal: number;
  weaknessesVal: number;
  threatsVal: number;
  factorsTotal: number;
  swotTemplateId: DocumentReference;
  hasVoices?: boolean;
  strengthsAmounts?: object;
  opportunitiesAmounts?: object;
  weaknessesAmounts?: object;
  threatsAmounts?: object;
}

export interface SwotVoice {
  id?: string;
  auditUid?: string;
  creationDate?: Timestamp;
  aspectType: SwotAspectType;
  factorType: string;
  reference: string;
  impact: SwotImpact;
  probability?: number;
  improvement?: string;
  responsible?: string;
  timing?: string;
  action?: {
    type: string;
    remarks: string;
  };
}

export interface SwotTemplate {
  id?: string;
  opportunities: {
    points: SwotTemplatePoint[],
    title: SwotAspectType.OPPORTUNITIES
  };
  strengths: {
    points: SwotTemplatePoint[],
    title: SwotAspectType.STRENGTHS
  };
  threats: {
    points: SwotTemplatePoint[],
    title: SwotAspectType.THREATS
  };
  weaknesses: {
    points: SwotTemplatePoint[],
    title: SwotAspectType.WEAKNESSES
  };
}

export interface SwotTemplatePoint {
  src: string;
  name: string;
}

export interface SwotDetailNavLink {
  label: string;
  link: string;
  name: string;
  index: number;
}

export enum SwotVoiceActionType {
  CORRECTIVE = 'corrective',
  OBJECTIVE = 'objective'
}

export enum SwotAspectType {
  STRENGTHS = 'strengths',
  OPPORTUNITIES = 'opportunities',
  WEAKNESSES = 'weaknesses',
  THREATS = 'threats'
}

export enum SwotImpact {
  NEGLIGIBLE = 1,
  CONTENT = 2,
  SIGNIFICANT = 3,
  RELEVANT = 4,
  CATASTROPHIC = 5
}

export const SwotAspects = {
  strengths: SwotAspectType.STRENGTHS,
  opportunities: SwotAspectType.OPPORTUNITIES,
  weaknesses: SwotAspectType.WEAKNESSES,
  threats: SwotAspectType.THREATS
};
