import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ProsaUserProfile } from '../interfaces';
import { ProsaError } from '../error';
import { ProsaAuthService } from './auth.service';

@Injectable()
export class ProfileService {
  constructor(
    private _firebaseStore: AngularFirestore,
    private _authService: ProsaAuthService
    ) {}

  async createProfile(profile: Partial<ProsaUserProfile>): Promise<any> {
    const authId = this._authService.userId;
    if (!authId) {
      throw new ProsaError('auth.auth-guard.error.not-authenticated');
    }

    try {
      await this._firebaseStore.doc<Partial<ProsaUserProfile>>(`profiles/${authId}`).set(profile, {merge: true});
    } catch (error) {
      if (error && error.code === 'permission-denied') {
        throw new ProsaError('service.error.permission-denied');
      }
      throw error;
    }
  }

  updateProfile(id: string, data): Promise<any> {
    return this._firebaseStore.doc(`profiles/${id}`)
      .update(data).then(() => data);
  }
}
