import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationItalian } from 'app/navigation/i18n/it';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { OnlineStatusService } from './shared/services/online-status.service';

@Component({
  selector   : 'app',
  templateUrl: './app.component.html',
  styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {DOCUMENT} document
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {FuseSplashScreenService} _fuseSplashScreenService
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   * @param {Platform} _platform
   * @param {TranslateService} _translateService
   */
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer,
    public onlineStatusService: OnlineStatusService,
  ) {
    // Add languages
    this._translateService.addLangs(['en', 'it']);

    // Set the default language
    this._translateService.setDefaultLang('en');

    // Set the navigation translations
    this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationItalian);

    // Use a language
    this._translateService.use('it');

    // SVG Library
    this._matIconRegistry.addSvgIcon('improve', _sanitizer.bypassSecurityTrustResourceUrl('assets/images/improve.svg'));
    this._matIconRegistry.addSvgIcon('eye-opened', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/eyes/eye-opened.svg'));
    this._matIconRegistry.addSvgIcon('eye-closed', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/eyes/eye-closed.svg'));
    this._matIconRegistry.addSvgIcon('arrow_left', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrows/arrow_left.svg'));
    this._matIconRegistry.addSvgIcon('arrow_bottom_outline', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrows/arrow_bottom_outline.svg'));
    this._matIconRegistry.addSvgIcon('borders', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/borders.svg'));
    this._matIconRegistry.addSvgIcon('organization', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/organization.svg'));
    this._matIconRegistry.addSvgIcon('redistribution', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/redistribution.svg'));
    this._matIconRegistry.addSvgIcon('profile', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/profile.svg'));
    this._matIconRegistry.addSvgIcon('settings', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings.svg'));
    this._matIconRegistry.addSvgIcon('sign_out', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sign_out.svg'));
    this._matIconRegistry.addSvgIcon('bell', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/bell.svg'));
    this._matIconRegistry.addSvgIcon('swot-positivo', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/swot-positivo.svg'));
    this._matIconRegistry.addSvgIcon('warn', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/warn.svg'));
    this._matIconRegistry.addSvgIcon('diagram', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/diagram.svg'));
    this._matIconRegistry.addSvgIcon('mailbox', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mailbox.svg'));
    this._matIconRegistry.addSvgIcon('document', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/document.svg'));
    this._matIconRegistry.addSvgIcon('add', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg'));
    this._matIconRegistry.addSvgIcon('trash', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/trash.svg'));
    this._matIconRegistry.addSvgIcon('modify', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/modify.svg'));
    this._matIconRegistry.addSvgIcon('copy', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/copy.svg'));
    this._matIconRegistry.addSvgIcon('share', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/share.svg'));
    this._matIconRegistry.addSvgIcon('people', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/people.svg'));
    this._matIconRegistry.addSvgIcon('coins', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/coins.svg'));
    this._matIconRegistry.addSvgIcon('photo-camera', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/photo-camera.svg'));
    this._matIconRegistry.addSvgIcon('check', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/check.svg'));
    this._matIconRegistry.addSvgIcon('lock', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/lock.svg'));
    this._matIconRegistry.addSvgIcon('pencil', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pencil.svg'));
    this._matIconRegistry.addSvgIcon('print', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/print.svg'));
    this._matIconRegistry.addSvgIcon('mail', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mail.svg'));

    this._matIconRegistry.addSvgIcon('impact1', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/impacts/impact1.svg'));
    this._matIconRegistry.addSvgIcon('impact2', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/impacts/impact2.svg'));
    this._matIconRegistry.addSvgIcon('impact3', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/impacts/impact3.svg'));
    this._matIconRegistry.addSvgIcon('impact4', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/impacts/impact4.svg'));
    this._matIconRegistry.addSvgIcon('impact5', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/impacts/impact5.svg'));

    this._matIconRegistry.addSvgIcon('paypal', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/payment-methods/paypal.svg'));
    this._matIconRegistry.addSvgIcon('credit-card', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/payment-methods/credit-card.svg'));
    this._matIconRegistry.addSvgIcon('new-user', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/new-user.svg'));
    this._matIconRegistry.addSvgIcon('user', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/user.svg'));
    this._matIconRegistry.addSvgIcon('edit-user', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit-user.svg'));
    this._matIconRegistry.addSvgIcon('versioning', _sanitizer.bypassSecurityTrustResourceUrl('assets/icons/versioning.svg'));


    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     */

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    setTimeout(() => {
      this._translateService.setDefaultLang('en');
      this._translateService.setDefaultLang('it');
    });

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // Add is-mobile class to the body if the platform is mobile
    if (this._platform.ANDROID || this._platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {

        this.fuseConfig = config;

        // Boxed
        if ( this.fuseConfig.layout.width === 'boxed' ) {
          this.document.body.classList.add('boxed');
        } else {
          this.document.body.classList.remove('boxed');
        }

        // Color theme - Use normal for loop for IE11 compatibility
        // tslint:disable-next-line:prefer-for-of
        for ( let i = 0; i < this.document.body.classList.length; i++ ) {
          const className = this.document.body.classList[i];

          if (className.startsWith('theme-')) {
            this.document.body.classList.remove(className);
          }
        }

        this.document.body.classList.add(this.fuseConfig.colorTheme);
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
