import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'prosa-error-mail-not-verified-popover',
  templateUrl: './mail-not-verified.component.html',
  styleUrls: ['./mail-not-verified.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProsaErrorMailNotVerifiedComponent {


  idUser: firebase.User;
  subTitle: string;
  desc: string;

  constructor(
    public dialogRef: MatDialogRef<ProsaErrorMailNotVerifiedComponent>,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data) {

    this.idUser = this.data.idUser;
    this.subTitle = this.data.subTitle ? this.data.subTitle : null;
    this.desc = this.data.desc ? this.data.desc : null;
  }

  onActionClick() {
    this.dialogRef.close();
  }

  resendMail() {
    if (this.idUser)
      this.idUser.sendEmailVerification().then(() => {
        console.log("Sended");
        this.dialogRef.close(0)
      });
    else
      //TEMPORANY
      alert("Per richiedere un nuovo link di verifica prima effettua il login")
  }

} 
