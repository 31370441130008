import { ProsaControl } from '../../prosa.control';
import { Component, Input, HostBinding, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
    selector: 'prosa-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ProsaButtonComponent extends ProsaControl implements OnInit {
    @Input() text: string;
    @Input() width: number;
    @Input() disabled: boolean;
    @Input() icon?: string;
    @Input() white?: boolean;
    @Output() prosaClick = new EventEmitter<any>();

    @HostBinding('class')
    class = 'prosa-button';

    ngOnInit(): void { }

    onClick(event): void {
        this.prosaClick.emit(event);
    }
}
