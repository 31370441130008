import { Routes } from '@angular/router';
import { RouteHelper } from '../../helpers/route-helper';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
  {
    path: RouteHelper.AUTH_PATH,
    loadChildren: () => import('../auth/auth.module').then(module =>  module.AuthModule)
  },
  {
    path: RouteHelper.EMPTY_PATH,
    canActivate: [AuthGuard],
    children: [
      {
        path: RouteHelper.HOME_PATH,
        loadChildren: () => import('../pages/home/home.module').then(module => module.HomeModule)
      },
      {
        path: RouteHelper.SWOT_PATH,
        loadChildren: () => import('../pages/swot/swot.module').then(module => module.SwotModule)
      },
      {
        path: RouteHelper.PROCESSES_PATH,
        loadChildren: () => import('../pages/process/process.module').then(module => module.ProcessModule)
      },
      {
        path: RouteHelper.PRICES_PATH,
        loadChildren: () => import('../pages/prices/prices.module').then(module => module.PricesModule)
      },
      {
        path: RouteHelper.SUBSCRIPTION_PATH,
        loadChildren: () => import('../pages/subscription/subscription.module').then(module => module.SubscriptionModule)
      },
      {
        path: RouteHelper.PRIVACY_PATH,
        loadChildren: () => import('../pages/privacy/privacy.module').then(module => module.PrivacyModule)
      },
      {
        path: RouteHelper.CONDITION_OF_USE_PATH,
        loadChildren: () => import('../pages/condition-of-use/condition-of-use.module')
          .then(module => module.ConditionOfUseModule)
      },
      { path: '**', redirectTo: RouteHelper.HOME_PATH },
    ],
  },
  { path: '**', redirectTo: RouteHelper.AUTH_PATH },
];
