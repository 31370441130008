import { DocumentReference } from '@angular/fire/firestore';
import { Timestamp } from './Timestamp';

export interface Process {
  $id?: string;
  auditUid?: string;
  $path?: string;
  id?: string;
  profileId: DocumentReference;
  creationDate: Timestamp;
  description: string;
  sector: string;
  activitiesNumber: number;
  riskLevel: number;
  risks: object;
  hasPhase?: boolean;
  versionNumber?: number;
  versions?: DocumentReference[];
  parentId?: string;
  $canEdit?: boolean;
}

export interface ProcessPhase {
  id: string;
  auditUid?: string;
  description: string;
  order: number;
}

export interface ProcessPhaseNode extends ProcessPhase {
  children?: ProcessSubPhase[];
}

export interface ProcessSubPhase {
  id: string;
  auditUid?: string;
  description: string;
  order: number;
  parentId: string;
}

export interface ProcessSubPhaseNode extends ProcessSubPhase {
  children?: ProcessActivity[];
}

export interface ProcessActivity {
  id: string;
  auditUid?: string;
  description: string;
  order: number;
  criticalIssues: boolean;
  bluePrint: boolean;
  coreSupport: boolean;
  parallel: boolean;
  responsible: string;
  place: string;
  parentId: string;
  documents: string;
  tools: string;
  indicator: string;
  costs: string;
  time: string;
  risk: ProcessRisk;
  riskPrediction: ProcessRisk;
  riskAnalysis: ProcessRiskAnalysis;
  improveAnalysis: ProcessImproveAnalysis;
}

export type PhaseTreeNode = ProcessPhaseNode | ProcessSubPhaseNode | ProcessActivity;

export interface ProcessRisk {
  impact: number;
  probability: number;
  detection: number;
  riskExpected: number;
  riskReduction: number;
}

export interface ProcessRiskAnalysis {
  typologies: string[];
  controls: string;
  errors: string;
  effects: string;
  cause: string;
  interventions: string;
}

export interface ProcessImproveAnalysis {
  responsiblePerson: string;
  document: string;
  completionDate: Timestamp | Date;
}

export interface Risk {
  name: string;
  threshold: number;
}

export interface RiskCriteria {
  value: number | string;
  name: string;
  color?: string;
  icon?: string;
}

export const RiskColors = {
  negligible: '#45d3f4',
  low: '#77efb7',
  considerable: '#f8d54a',
  high: '#ff8f00',
  critic: '#fb5b5b'
};

export const getRisks = (locale: string = 'it') => {
  return {
    negligible: { name: riskNames.negligible[locale], threshold: 4 },
    low: { name: riskNames.low[locale], threshold: 18 },
    considerable: { name: riskNames.considerable[locale], threshold: 48 },
    high: { name: riskNames.high[locale], threshold: 100 },
    critic: { name: riskNames.critic[locale], threshold: 125 }
  };
};

const riskNames = {
  negligible: { it: 'trascurab.', en: 'negligib.' },
  low: { it: 'basso', en: 'low' },
  considerable: { it: 'rilevante', en: 'consider.' },
  high: { it: 'critico', en: 'high' },
  critic: { it: 'alto', en: 'critic' },
};

export const getImpacts = (locale: string = 'it'): RiskCriteria[] => {
  return [
    { value: 1, name: impactNames[0][locale], color: RiskColors.negligible, icon: 'check_circle' },
    { value: 2, name: impactNames[1][locale], color: RiskColors.low, icon: 'check_circle' },
    { value: 3, name: impactNames[2][locale], color: RiskColors.considerable, icon: 'error' },
    { value: 4, name: impactNames[3][locale], color: RiskColors.high, icon: 'error' },
    { value: 5, name: impactNames[4][locale], color: RiskColors.critic, icon: 'error' }
  ];
};

const impactNames = [
  { it: 'Nessun danno', en: 'No damage' },
  { it: 'Minore', en: 'Minor' },
  { it: 'Moderata', en: 'Moderate' },
  { it: 'Maggiore', en: 'Greater' },
  { it: 'Catastrofico', en: 'Catastrophic' },
];

export const getProbabilities = (locale: string = 'it'): RiskCriteria[] => {
  return [
    { value: 1, name: probabilityNames[0][locale], color: RiskColors.negligible, icon: 'check_circle' },
    { value: 2, name: probabilityNames[1][locale], color: RiskColors.low, icon: 'check_circle' },
    { value: 3, name: probabilityNames[2][locale], color: RiskColors.considerable, icon: 'error' },
    { value: 4, name: probabilityNames[3][locale], color: RiskColors.high, icon: 'error' },
    { value: 5, name: probabilityNames[4][locale], color: RiskColors.critic, icon: 'error' }
  ];
};

const probabilityNames = [
  { it: 'Impossibile', en: 'Impossible' },
  { it: 'Remoto', en: 'Unattainable' },
  { it: 'Non comune', en: 'Uncommon' },
  { it: 'Occasionale', en: 'Occasional' },
  { it: 'Frequente', en: 'Frequent' },
];

export const getDetections = (locale: string = 'it'): RiskCriteria[] => {
  return [
    { value: 1, name: detectionNames[0][locale], color: RiskColors.negligible, icon: 'check_circle' },
    { value: 2, name: detectionNames[1][locale], color: RiskColors.low, icon: 'check_circle' },
    { value: 3, name: detectionNames[2][locale], color: RiskColors.considerable, icon: 'error' },
    { value: 4, name: detectionNames[3][locale], color: RiskColors.high, icon: 'error' },
    { value: 5, name: detectionNames[4][locale], color: RiskColors.critic, icon: 'error' }
  ];
};

const detectionNames = [
  { it: 'Certa', en: 'Certain' },
  { it: 'Alta', en: 'High' },
  { it: 'Media', en: 'Average' },
  { it: 'Bassa', en: 'Low' },
  { it: 'Nulla', en: 'Nothing' },
];

export const convertRiskLevelToType = (riskLevel: number) => {
  if (!riskLevel) { return ''; }
  const risks = Object.keys(getRisks());
  for (const risk of risks) {
    if (riskLevel <= getRisks()[risk].threshold) {
      return risk;
    }
  }
  return '';
};
