import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { ProsaAuthService, ProsaError } from 'app/shared';
import { ISubscriptionPurchase } from 'app/modules/pages/subscription/interfaces/subscription-purchase.interface';
import { extractDocument } from 'app/shared/utils';
import { catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import * as firebase from 'firebase';
import { subscriptionTypes } from 'app/shared/interfaces';

@Injectable()
export class SubscriptionPurchaseProvider {

    /**
 * Constructor
 *
 * @param {AngularFirestore} _firebaseStore
 * @param {ProsaAuthService} _authService
 */
    constructor(
        private _firebaseStore: AngularFirestore,
        private _authService: ProsaAuthService) { }

    getSubsciptionById(id: string) {
        return this._firebaseStore.doc('subscriptionPurchase/' + id)
            .snapshotChanges()
            .pipe(
                extractDocument(),
                catchError(err => {
                    console.error(err);
                    return of([]);
                })
            ) as Observable<ISubscriptionPurchase>;
    }

    async addSubscriptionPurchase(subscription: ISubscriptionPurchase, numberOfAssignedUsers: number): Promise<DocumentReference> {
        try {
            const newSubscription = {
                ...subscription,
                profileId: this._firebaseStore.doc(`profiles/${this._authService.userId}`).ref
            } as ISubscriptionPurchase;

            const doc = this._firebaseStore.doc('subscriptionPurchase/' + subscription.id);
            doc.set(newSubscription);

            const now = this._authService.user$.value.expirationDate && this._authService.user$.value.expirationDate.toDate() >= new Date()
                ? this._authService.user$.value.expirationDate.toDate() : new Date();

            this._firebaseStore.doc(`profiles/${this._authService.userId}`).ref.update({
                subscriptionType: subscription.plan,
                subsciptionPurchaseReference: firebase.firestore.FieldValue.arrayUnion(doc.ref),
                expirationDate: new Date(now.getFullYear() + 1, now.getMonth(), now.getDate()),
                paymentTransaction: firebase.firestore.FieldValue.arrayUnion(new Date),
                numberOfAssignedUsers: numberOfAssignedUsers,
                assignedUsers: []
            });

            return doc.ref;

        } catch (error) {
            throw new ProsaError(error);
        }
    }

    convertPlan(date: Date, type: string, numberOfUsers?: number) {

        const profileUpdate = type === subscriptionTypes.gold ? {
            subscriptionType: subscriptionTypes.gold,
            expirationDate: date,
            conversionPlanToGoldDate: firebase.firestore.FieldValue.arrayUnion(new Date)
        } : {
                subscriptionType: subscriptionTypes.platinum,
                expirationDate: date,
                conversionPlanToPlatinumDate: firebase.firestore.FieldValue.arrayUnion(new Date),
                assignedUsers: [],
                numberOfAssignedUsers: numberOfUsers ? numberOfUsers : 0
            };

        const subUpdate = type === subscriptionTypes.gold ? {
            hasBeenConvertedToGold: true,
            conversionDateToGold: new Date()
        } : {
                hasBeenConvertedToPlatinum: true,
                conversionDateToPlatinum: new Date()
            };

        try {

            const subsciptionPurchaseReference = this._authService.user$.value.subsciptionPurchaseReference;
            const index = subsciptionPurchaseReference.length - 1;

            this._firebaseStore.doc(`profiles/${this._authService.userId}`).ref.update({
                ...profileUpdate
            });

            this._firebaseStore.doc('subscriptionPurchase/' + subsciptionPurchaseReference[index].id).ref.update({
                ...subUpdate
            });

        } catch (error) {
            throw new ProsaError(error);
        }
    }
}