import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SubscriptionService } from 'app/shared/services/subscription.service';

@Component({
  selector: 'prosa-print-process-popup',
  templateUrl: './print-process.component.html',
})
export class PrintProcessPopupComponent implements OnInit {

  get isSecondOptionDisabled(): boolean {
    if (!this._subscriptionService.isGoldSubscriber && !this._subscriptionService.isPlatinumSubscriber) {
      return false;
    }
  }

  get isThirdOptionDisabled(): boolean {
    if (!this._subscriptionService.isGoldSubscriber && !this._subscriptionService.isPlatinumSubscriber) {
      return false;
    }
  }

  constructor(
    public dialogRef: MatDialogRef<PrintProcessPopupComponent>,
    private _subscriptionService: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void { }

  onClose(payload: string): void {
    this.dialogRef.close(payload);
  }

  onSelect(option: string): void {
    let type: string;
    switch (option) {
      case 'first': type = 'synthetic'; break;
      case 'second': type = 'extended'; break;
      case 'third': type = 'plans'; break;
      default: type = 'error';
    }
    this.onClose(type);
  }
}
