export const locale = {
  lang: 'en',
  data: {
    // MODULE: SHARED
    sharedModule: {
      // COMPONENTS
      components: {
        // COMPONENT: PRINT REPORT
        printReport: {
          title: 'Print SWOT',
          content: {
            title: 'Select the print format of the SWOT',
            // tslint:disable-next-line:max-line-length
            desctiption: '(EN) Curabitur lobortis id lorem id bibendum. Ut id consectetur magna. Quisque volutpat augue enim, pulvinar lobortis nibh lacinia at. Vestibulum nec erat ut mi sollicitudin porttitor id sit amet risus. Nam tempus vel odio vitae aliquam. In imperdiet eros id lacus vestibulum vestibulum.',
          },
          options: {
            first: 'Synthetic',
            second: 'Extended',
            third: 'Migration plan'
          },
        },
        printProcess: {
          title: 'Stampa processo',
          content: {
            title: 'Selezionare il formato di stampa del processo',
            // tslint:disable-next-line:max-line-length
            desctiption: 'Sono disponibili due formati di stampa del processo selezionato. La stampa sintetica prevede la stampa su foglio A4 delle quattro aree, con i valori per singola voce ed i grafici. La stampa estesa, prevista su foglio A3, estrae nel dettaglio tutte le voci inserite',
          },
          options: {
            first: 'Sintetica',
            second: 'Estesa'
          },
        },
        // COMPONENT: CONFIRM DELETE
        confirmDelete: {
          title: 'DELETE RECORD',
          description: 'Do you want to delete this record?',
          actions: {
            cancel: 'Cancel',
            delete: 'Delete',
          },
          subDescPhase: 'E tutte le eventuali sotto fasi',
          subDescSubPhase: 'E tutte le eventuali attività'
        },
        // COMPONENT: CHANGE PASSWORD
        changePassword: {
          title: 'Change Password!',
          description: 'Enter your current password and enter the new one',
          currentPassword: 'Current Password',
          newPassword: 'New Password',
          confirmPassword: 'Confirm Password',
          confirm: 'CONFIRM',
        },
        // COMPONENT: CONTENT POPUP
        contentPopup: {
          confirm: 'CONFIRM',
        },
        // COMPONENT: EMAIL NOT VERIFIED
        emailNotVerfied: {
          title: 'Conferma registrazione',
          subTitle: 'Attenzione è stata inviata una mail per la conferma \ndella registrazione',
          desc: 'Verificare la propria casella di posta e utilizzare il link \nriportato per confermare la propria registrazione.',
          resendButton: 'Invia nuova Email',
          defaultButton: 'Continua'
        }
      },
    },
  }
};
