import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { extractDocument } from 'app/shared/utils';
import { take, catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SWOT, ProsaError, SwotTemplate } from 'app/shared';

@Injectable()
export class SwotTemplateProvider {

    /**
     * Constructor
     *
     * @param {AngularFirestore} _firebaseStore
     */
    constructor(
        private _firebaseStore: AngularFirestore,
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Swot template
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get swot template
     * 
     *  @returns {Observable<SwotTemplate>}
     */
    getSwotTemplate(swotId): Observable<SwotTemplate> {
        // First we should retrieve swot. Because without it would fail while first navigate to this swot details
        return this._firebaseStore.doc(`swots/${swotId}`).snapshotChanges()
            .pipe(
                extractDocument<SWOT>(),
                switchMap((data: SWOT) => this._firebaseStore.doc(`${data.swotTemplateId.path}`).collection('it').snapshotChanges()),
                map((actions: []) => {
                    return actions.map((a: any) => {
                        const data = a.payload.doc.data();
                        return {...data};
                    })[0];
                }),
                catchError(err => {
                    console.error(err);
                    return of([]);
                })
            );
    }

    /**
     * Get factor points for swot template
     */
    async getSwotTemplatePoints() {
        try {
            const swotTemplate = await this.getLastSwotTemplate();
            const swotTemplatePoints = await this._firebaseStore.doc(`swotTemplates/${swotTemplate.id}`).collection('it').snapshotChanges()
            .pipe(
                take(1),
                map((actions: []) => {
                    return actions.map((a: any) => {
                        const data = a.payload.doc.data();
                        return {...data};
                    })[0];
                }),
                catchError(err => {
                    console.error(err);
                    return of([]);
                })
            ).toPromise();

            return swotTemplatePoints;
        } catch (error) {
            throw new ProsaError(error);            
        }
   }

    /**
     * Get last swot template by datetimeCreated field
     * 
     * @returns {Promise<any>}
     */
    async getLastSwotTemplate(): Promise<any> {
        try {
            const swotTemplate = await this._firebaseStore.collection(`swotTemplates`, ref => ref
                .orderBy('datetimeCreated', 'desc')
                .limit(1)
            ).snapshotChanges()
            .pipe(
                take(1),
                map((actions: []) => {
                    return actions.map((a: any) => {
                        const id = a.payload.doc.id;
                        const data = a.payload.doc.data();
                        return {id, ...data};
                    })[0];
                }),
                tap(data => console.log('TEMPLATE', data))
            ).toPromise();

            return swotTemplate;

        } catch (error) {
            throw new ProsaError(error);            
        }
    }
}