// https://github.com/angular/angularfire2/issues/1405

import { MonoTypeOperatorFunction, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';



/**
 * Make 'cold' observable 'hot', + modified BehaviourSubject (no emit value if it's undefined)
 *
 * We can use {@link defer} if this function cause trouble
 * http://reactivex.io/documentation/operators/defer.html
 *
 * @deprecated use cache()
 */
export function deferCache<T>(cold: () => Observable<T>): Observable<T> {
  const subject = new Subject();
  let refs = 0;
  let _value;

  const mainSub = cold().pipe(tap(v => {
    _value = v;
  })).subscribe(subject);

  return new Observable((observer) => {
    refs++;

    // emit last value
    if (_value !== undefined) {
      observer.next(_value);
    }

    // subscribe to new values
    const sub = subject.subscribe(observer);

    // unsubscribe logic
    return () => {
      refs--;
      if (refs === 0) {
        mainSub.unsubscribe();
      }
      sub.unsubscribe();
    };
  });
}


// Note: we need 'defer' (or cache() pipe) because of the issue:
// Second subscribe on Observable from snapshotChanges() does not emit the current snapshot
// https://github.com/angular/angularfire2/issues/1405
/**
 * originalSource -> subject -> customObservable
 */
export function cache<T>(): MonoTypeOperatorFunction<T> {
  let refs = 0;
  let value;
  let mainSubscription;

  const subject = new Subject();

  return (source: Observable<T>) => new Observable(subscriber => {
    if (refs === 0) {
      // console.log('[pipe] cache: subscribe to original');
      // first subscriber. Subscribe to the original source
      mainSubscription = source.pipe(tap(v => {
        value = v;
      })).subscribe(subject);
    } else {
      // emit last value (if any)
      if (value !== undefined) {
        subscriber.next(value);
      }
    }
    refs++;

    // console.log('[pipe] cache: subscribe to cached');
    // subscribe to changes of the original subject
    const sub = subject.subscribe(subscriber);

    // unsubscribe logic
    return () => {
      refs--;
      // console.log('[pipe] cache: release cached');
      sub.unsubscribe();

      if (refs === 0) {
        // console.log('[pipe] cache: release original');
        mainSubscription.unsubscribe();
      }
    };
  });
}


// Note: we need 'defer' (or cache() pipe) because of the issue:
// Second subscribe on Observable from snapshotChanges() does not emit the current snapshot
// https://github.com/angular/angularfire2/issues/1405
/**
 * originalSource -> subject -> customObservable
 */
export function cacheTime<T>(timeout: number): MonoTypeOperatorFunction<T> {
  let refs = 0;
  let value;
  let mainSubscription;
  let timer;

  const subject = new Subject();

  return (source: Observable<T>) => new Observable(subscriber => {
    if (refs === 0) {

      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      // console.log('[pipe] cache: subscribe to original');
      // first subscriber. Subscribe to the original source
      mainSubscription = source.pipe(tap(v => {
        value = v;
      })).subscribe(subject);
    } else {
      // emit last value (if any)
      if (value !== undefined) {
        subscriber.next(value);
      }
    }
    refs++;

    // console.log('[pipe] cache: subscribe to cached');
    // subscribe to changes of the original subject
    const sub = subject.subscribe(subscriber);

    // unsubscribe logic
    return () => {
      refs--;
      // console.log('[pipe] cache: release cached');
      sub.unsubscribe();

      if (refs === 0) {
        timer = setTimeout(() => {
          // console.log('[pipe] cache: release original');
          mainSubscription.unsubscribe();
        }, timeout);
      }
    };
  });
}

/**
 *
 */
export function timeoutPromise(interval = 0) {
  return function () {
    const caller = this;
    const args = Array.prototype.slice.apply(arguments);
    return new Promise(resolve => {
      setTimeout(resolve.apply(caller, args), interval);
    });
  };
}

/*
export function clamp<T>(min?: T, max?: T): MonoTypeOperatorFunction<T> {
  return map((element: T) => {
    if (min !== undefined && element < min) {
      return min;
    }
    if (max !== undefined && element > max) {
      return max;
    }
    return element;
  });
}
*/

export function filterArray<T>(filterFn: (el: T, i: number, arr: T[]) => boolean): MonoTypeOperatorFunction<T[]> {
  return map((elements: T[]) => elements.filter(filterFn));
}


