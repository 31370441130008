import { Component, OnInit, Input, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { ProsaControl } from '../../prosa.control';

const THEME_OUTLINE = 'outline';
const THEME_OUTLINE_LIGHT = 'outline-light';

@Component({
  selector: 'prosa-input',
  templateUrl: './input.component.html',
})
export class ProsaInputComponent extends ProsaControl implements OnInit, OnChanges {
  @Input() textarea = false;
  @Input() type = 'text';
  @Input() autocomplete = 'off';
  @Input() theme?: string;
  @Input() disabled?: boolean;

  mode: string;
  appearance: string;

  @HostBinding('class')
  class = 'prosa-input';

  @HostBinding('class.prosa-textarea')
  get isTextArea(): boolean {
    return !!this.textarea;
  }

  /**
   * MODIFICATORS
   */
  @HostBinding('class.prosa-input__empty')
  get isEmpty(): boolean {
    return !this.control || !this.control.value;
  }

  /**
   * THEME
   */
  @HostBinding('class.prosa-theme-outline')
  get isOutlineTheme(): boolean {
    return this.theme === THEME_OUTLINE;
  }

  @HostBinding('class.prosa-theme-outline-light')
  get isOutlineLightTheme(): boolean {
    return this.theme === THEME_OUTLINE_LIGHT;
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.disabled && this.control) {
      simpleChanges.disabled.currentValue ? this.control.disable() : this.control.enable();
    }
  }

  ngOnInit(): void {
    this.mode = this.type || 'text';

    if (
      this.theme === THEME_OUTLINE ||
      this.theme === THEME_OUTLINE_LIGHT
    ) {
      this.appearance = 'outline';
    } else {

    }
  }

  /** Password mode */
  togglePasswordMode(event: any): void {
    event.stopPropagation();
    this.mode = this.mode === 'password' ? 'text' : 'password';
  }

  isPasswordVisible(): boolean {
    return this.mode === 'text';
  }

  isDateType(): boolean {
    return this.type === 'date';
  }
}
