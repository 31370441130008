import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { extractSnapshotDocumentsPipe, extractQuerySnapshotPipe } from 'app/shared/utils';
import { take, catchError, tap, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ProsaUserProfile } from 'app/shared/interfaces';

@Injectable()
export class UserProvider {
  firstInResponse;
  lastInResponse;
  pageIndex = 0;
  pageSize = 10;
  paginationClickedCount = 0;
  prevStrtAt: any = [];

  constructor(
    private _firebaseStore: AngularFirestore,
  ) { }

  getAll(): Observable<ProsaUserProfile[]> {
    return this._firebaseStore.collection(`profiles`, ref => ref.orderBy('name')).get()
      .pipe(
        take(1),
        extractQuerySnapshotPipe(),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      ) as Observable<ProsaUserProfile[]>;
  }

  getByPath(path: string): Observable<ProsaUserProfile> {
    return this._firebaseStore.doc(path).get()
      .pipe(
        take(1),
        map(user => {
          return {
            id: user.ref,
            ...user.data() as ProsaUserProfile
          }
        }),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      ) as Observable<ProsaUserProfile>;
  }

  getAllUsersActive(): Observable<ProsaUserProfile[]> {
    return this._firebaseStore.collection(`profiles`, ref => ref.where('disabled', '==', false)).get()
      .pipe(
        extractQuerySnapshotPipe(),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      ) as Observable<ProsaUserProfile[]>;
  }

  getFiltered(): Observable<ProsaUserProfile[]> {
    this.lastInResponse = null;
    this.firstInResponse = null;
    this.paginationClickedCount = 0;
    this.prevStrtAt = [];

    return this.getAll();
  }

  loadInitialPage(pageSize: number): Observable<ProsaUserProfile[]> {
    this.lastInResponse = null;
    this.firstInResponse = null;
    this.paginationClickedCount = 0;
    this.prevStrtAt = [];
    this.pageIndex = 0;
    return this._firebaseStore.collection<ProsaUserProfile>(`profiles`, ref => ref
      .where('roleType', '==', 'user')
      .limit(pageSize)
    )
      .snapshotChanges()
      .pipe(
        take(1),
        tap(actions => {
          this.firstInResponse = actions[0].payload.doc;
          this.lastInResponse = actions[actions.length - 1].payload.doc;
          this.paginationClickedCount = 0;
          this.prevStrtAt = [];
          // Push first item to use for Previous action
          this._pushPrevStartAt(this.firstInResponse);
        }),
        extractSnapshotDocumentsPipe(),
        catchError(err => {
          console.error(err);
          return of([]);
        }),
      ) as Observable<ProsaUserProfile[]>;
  }

  loadUpdatedPage(pageSize): Observable<ProsaUserProfile[]> {
    return this._firebaseStore.collection(`profiles`, ref => ref
      .where('roleType', '==', 'user')
      .limit(pageSize)
      .startAt(this.firstInResponse)
    )
      .snapshotChanges()
      .pipe(
        take(1),
        extractSnapshotDocumentsPipe()
      ) as Observable<ProsaUserProfile[]>;
  }

  loadNextPage(pageSize: number): Observable<ProsaUserProfile[]> {
    return this._firebaseStore.collection(`profiles`, ref => ref
      .where('roleType', '==', 'user')
      .limit(pageSize)
      .startAfter(this.lastInResponse)
    )
      .snapshotChanges()
      .pipe(
        take(1),
        tap(actions => {
          if (!actions[0]) { return; }
          this.firstInResponse = actions[0].payload.doc;
          this.lastInResponse = actions[actions.length - 1].payload.doc;
          this.paginationClickedCount++;
          this._pushPrevStartAt(this.firstInResponse);
        }),
        extractSnapshotDocumentsPipe()
      ) as Observable<ProsaUserProfile[]>;
  }

  loadPreviousPage(pageSize: number): Observable<ProsaUserProfile[]> {
    return this._firebaseStore.collection(`profiles`, ref => ref
      .where('roleType', '==', 'user')
      .startAt(this._getPrevStartAt())
      .endBefore(this.firstInResponse)
      .limit(pageSize)
    )
      .snapshotChanges()
      .pipe(
        take(1),
        tap(actions => {
          this.firstInResponse = actions[0].payload.doc;
          this.lastInResponse = actions[actions.length - 1].payload.doc;
          this.paginationClickedCount--;
          // Pop not required value in array
          this._popPrevStartAt(this.firstInResponse);
        }),
        extractSnapshotDocumentsPipe()
      ) as Observable<ProsaUserProfile[]>;
  }

  /**
   * Reset pagination
   */
  resetPagination(pageSize): void {
    this.lastInResponse = null;
    this.firstInResponse = null;
    this.pageSize = pageSize;
    this.paginationClickedCount = 0;
    this.prevStrtAt = [];
  }

  /**
   * Push previous startAt document
   *
   * @param prevFirstDoc
   */
  private _pushPrevStartAt(prevFirstDoc): void {
    if (this.prevStrtAt.map(el => el.id).indexOf(prevFirstDoc.id) === -1) {
      this.prevStrtAt.push(prevFirstDoc);
    }
  }

  /**
   * Pop previous startAt document
   *
   * @param prevFirstDoc
   */
  private _popPrevStartAt(prevFirstDoc): void {
    this.prevStrtAt.forEach(element => {
      if (prevFirstDoc.id === element.id) {
        element = null;
      }
    });
  }

  /**
   * Get previous startAt document
   *
   */
  private _getPrevStartAt(): any {
    if (this.prevStrtAt.length > (this.paginationClickedCount + 1)) {
      this.prevStrtAt.splice(this.prevStrtAt.length - 2, this.prevStrtAt.length - 1);
    }
    return this.prevStrtAt[this.paginationClickedCount - 1];
  }
}
