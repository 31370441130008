import { Component } from '@angular/core';
import { RouteHelper } from 'app/helpers/route-helper';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  get year(): number {
    return new Date().getFullYear();
  }

  constructor(public routeHelper: RouteHelper) { }
}
