import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'prosa-confirm-popup',
  templateUrl: './confirm-popup.component.html',
})
export class ConfirmPopupComponent implements OnInit {
  @Input() popupTitle: string;
  @Input() firstOptionTitle: string;
  @Input() secondOptionTitle: string;
  @Input() thirdOptionTitle: string;
  
  @Input() firstOptionDisabled = false;
  @Input() secondOptionDisabled = false;
  @Input() thirdOptionDisabled = false;
  
  @Input() disableClose: boolean;
  @Output() optionSelected: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public dialogRef: MatDialogRef<ConfirmPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void { }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(option: string): void {
    this.optionSelected.emit(option);
  }
}
