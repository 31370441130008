import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { extractSnapshotDocumentsPipe, extractDocument, extractQuerySnapshotPipe } from 'app/shared/utils';
import { take, catchError, tap, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Process, ProsaError, ProsaAuthService } from 'app/shared';
import { ProcessPhase, ProcessSubPhase, ProcessActivity } from '../../../../../../functions/src/lib/interface';

@Injectable()
export class ProcessProvider {
  firstInResponse;
  lastInResponse;
  pageIndex = 0;
  pageSize = 10;
  paginationClickedCount = 0;
  prevStrtAt: any = [];

  constructor(
    private _firebaseStore: AngularFirestore,
    private _authService: ProsaAuthService,
  ) { }

  getProcessPhases(processId): Promise<any> {
    return this._firebaseStore.doc(`processes/${processId}`).collection('phases').get().toPromise().then(snap => snap.empty);
  }

  getProcess(id: string): Observable<Process> {
    return this._firebaseStore.doc(`processes/${id}`)
      .snapshotChanges()
      .pipe(
        extractDocument(),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      ) as Observable<Process>;
  }

  getAllProcesses(profileId: string): Observable<Process[]> {
    return this._firebaseStore.collection(`processes`, ref => ref
      .where('profileId', '==', this._firebaseStore.doc(`profiles/${profileId}`).ref)
      .orderBy('creationDate', 'asc')
    )
      .snapshotChanges()
      .pipe(
        take(1),
        extractSnapshotDocumentsPipe(),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      ) as Observable<Process[]>;
  }

  getAllUsersProcesses(): Observable<Process[]> {
    return this._firebaseStore.collection(`processes`, ref => ref.orderBy('creationDate', 'asc'))
      .snapshotChanges()
      .pipe(
        take(1),
        extractSnapshotDocumentsPipe(),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      ) as Observable<Process[]>;
  }

  getIsPlatinumProcesses(ids: DocumentReference[]): Observable<Process[]> {
    return this._firebaseStore.collection(`processes`, ref => ref.orderBy('creationDate', 'asc') //.where('profileId', 'in', ids)
    )
      .snapshotChanges()
      .pipe(
        take(1),
        map(events => {
          return events.filter(ev => {
            const swto = ev.payload.doc.data() as Process;
            if (ids) {
              const idsR = ids.map(a => a.id);
              return idsR.includes(swto.profileId.id);
            } else return true;
          });
        }),
        extractSnapshotDocumentsPipe(),
        catchError(err => {
          console.error(err);
          return of([]);
        })
      ) as Observable<Process[]>;
  }

  getAllProcessesByCurrentRef(): Observable<Process[]> {
    const authId = this._authService.userId;

    if (!authId) {
      throw new ProsaError('auth.auth-guard.error.not-authenticated');
    }

    return this.getAllProcesses(authId);
  }

  async addProcess(process: Process, oldProcess?: Process): Promise<DocumentReference> {
    try {

      const processDocRef = await this._firebaseStore.collection(`processes`).add(process);

      if (oldProcess) {
        const oldPhases = await this._firebaseStore.collection('processes/' + oldProcess.id + '/phases')
          .snapshotChanges()
          .pipe(take(1), extractSnapshotDocumentsPipe())
          .toPromise();

        const oldSubPhases = await this._firebaseStore.collection('processes/' + oldProcess.id + '/subPhases')
          .snapshotChanges()
          .pipe(take(1), extractSnapshotDocumentsPipe())
          .toPromise();

        const oldActivities = await this._firebaseStore.collection('processes/' + oldProcess.id + '/activities')
          .snapshotChanges()
          .pipe(take(1), extractSnapshotDocumentsPipe())
          .toPromise();

        oldPhases.forEach((phase: ProcessPhase) => {
          this._firebaseStore.doc('processes/' + processDocRef.id + '/phases/' + phase.id).set({
            ...phase
          });
        });

        oldSubPhases.forEach((subPhase: ProcessSubPhase) => {
          this._firebaseStore.doc('processes/' + processDocRef.id + '/subPhases/' + subPhase.id).set({
            ...subPhase
          });
        });

        oldActivities.forEach((activity: ProcessActivity) => {
          this._firebaseStore.doc('processes/' + processDocRef.id + '/activities/' + activity.id).set({
            ...activity
          });
        });
      }

      // HOTFIX
      this.resetPagination(this.pageSize);

      return processDocRef;
    } catch (error) {
      throw new ProsaError(error);
    }
  }

  async deleteProcess(processId: string): Promise<void> {
    try {
      const processDocRef = await this._authService.deleteAtPath(`processes/${processId}`);
      // HOTFIX
      this.resetPagination(this.pageSize);
      return processDocRef;
    } catch (error) {
      throw new ProsaError(error);
    }
  }

  duplicateProcess(process: Process, oldProcess: Process): Promise<DocumentReference> {
    try {
      return this.addProcess(process, oldProcess);
      // TODO: duplicate subcollections
    } catch (error) {
      throw new ProsaError(error);
    }
  }

  editProcess(process: Partial<Process>): Promise<any> {
    try {
      return this._firebaseStore.doc(`processes/${process.id}`).update({
        creationDate: process.creationDate,
        description: process.description,
        sector: process.sector,
        profileId: process.profileId,
        auditUid: this._authService.userId,
        versions: process.versions ? process.versions : null
      });
    } catch (error) {
      throw new ProsaError(error);
    }
  }

  getFilteredProcesses(profileId: string, allUsers: boolean, isPLatinum: boolean, ids: DocumentReference[]): Observable<Process[]> {
    this.lastInResponse = null;
    this.firstInResponse = null;
    this.paginationClickedCount = 0;
    this.prevStrtAt = [];

    return allUsers ? this.getAllUsersProcesses() : isPLatinum ? this.getIsPlatinumProcesses(ids) : this.getAllProcesses(profileId);
  }

  loadInitialProcessPage(profileId: string, pageSize: number, allUsers: boolean, isPLatinum: boolean, ids?: DocumentReference[]): Observable<Process[]> {
    this.lastInResponse = null;
    this.firstInResponse = null;
    this.paginationClickedCount = 0;
    this.prevStrtAt = [];
    this.pageIndex = 0;
    return (allUsers ?
      this._initialAllUsersProcessPageCollection(pageSize) :
      isPLatinum ? this._initialIsPlatinumProcessPageCollection(pageSize, ids) :
        this._initialProcessPageCollection(profileId, pageSize))
      .snapshotChanges()
      .pipe(
        take(1),
        map(events => {
          return events.filter(ev => {
            const swto = ev.payload.doc.data() as Process;
            if (ids && isPLatinum) {
              const idsR = ids.map(a => a.id);
              return idsR.includes(swto.profileId.id);
            } else return true;
          });
        }),
        tap(actions => {
          this.firstInResponse = actions[0] ? actions[0].payload.doc : null;
          this.lastInResponse = actions[actions.length - 1] ? actions[actions.length - 1].payload.doc : null;
          this.paginationClickedCount = 0;
          this.prevStrtAt = [];
          // Push first item to use for Previous action
          this._pushPrevStartAt(this.firstInResponse);
        }),
        extractSnapshotDocumentsPipe(),
        switchMap(values => {
          values.map(async (value: Process) => {
            if (value.parentId) {
              const parent = (await this._firebaseStore.doc('processes/' + value.parentId).get().toPromise()).data() as Process;
              value.$canEdit = parent.versions.map(a => a.id).indexOf(value.id) === parent.versions.length - 1;
            }
            else value.$canEdit = true;
          });

          return of(values);
        }),
        catchError(err => {
          console.error(err);
          return of([]);
        }),
      ) as Observable<Process[]>;
  }

  private _initialAllUsersProcessPageCollection(pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`processes`, ref => ref
      .orderBy('creationDate', 'asc')
      .limit(pageSize)
    );
  }

  private _initialIsPlatinumProcessPageCollection(pageSize: number, ids: DocumentReference[]): AngularFirestoreCollection {
    if (ids.length < 10) {
      return this._firebaseStore.collection(`processes`, ref => ref.where('profileId', 'in', ids)
        .orderBy('creationDate', 'asc')
        .limit(pageSize)
      );
    } else {
      return this._firebaseStore.collection(`processes`, ref => ref //.where('profileId', 'in', ids)
        .orderBy('creationDate', 'asc')
        //.limit(pageSize)
      );
    }
  }

  private _initialProcessPageCollection(profileId: string, pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`processes`, ref => ref
      .where('profileId', '==', this._firebaseStore.doc(`profiles/${profileId}`).ref)
      .orderBy('creationDate', 'asc')
      .limit(pageSize));
  }

  loadUpdatedProcessPage(profileId: string, pageSize: number, allUsers: boolean, isPLatinum: boolean, ids: DocumentReference[]): Observable<Process[]> {
    return (allUsers ?
      this._updatedAllUsersProcessPageCollection(pageSize) :
      isPLatinum ? this._updatedIsPlatinumProcessPageCollection(pageSize, ids) :
        this._updatedProcessPageCollection(profileId, pageSize))
      .get()
      .pipe(
        take(1),
        extractQuerySnapshotPipe(),
        map(events => {
          return events.filter(ev => {
            const swto = ev as Process;
            if (ids && isPLatinum) {
              const idsR = ids.map(a => a.id);
              return idsR.includes(swto.profileId.id);
            } else return true;
          });
        }),
      ) as Observable<Process[]>;
  }

  private _updatedIsPlatinumProcessPageCollection(pageSize: number, ids: DocumentReference[]): AngularFirestoreCollection {
    if (ids.length < 10) {
      return this._firebaseStore.collection(`processes`, ref => ref.where('profileId', 'in', ids)
        .limit(pageSize)
        .orderBy('creationDate', 'asc')
        .startAt(this.firstInResponse)
      );
    } else {
      return this._firebaseStore.collection(`processes`, ref => ref //.where('profileId', 'in', ids)
        //.limit(pageSize)
        .orderBy('creationDate', 'asc')
        .startAt(this.firstInResponse)
      );
    }
  }

  private _updatedAllUsersProcessPageCollection(pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`processes`, ref => ref
      .limit(pageSize)
      .orderBy('creationDate', 'asc')
      .startAt(this.firstInResponse)
    );
  }

  private _updatedProcessPageCollection(profileId: string, pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`processes`, ref => ref
      .where('profileId', '==', this._firebaseStore.doc(`profiles/${profileId}`).ref)
      .limit(pageSize)
      .orderBy('creationDate', 'asc')
      .startAt(this.firstInResponse)
    );
  }

  loadNextProcessPage(profileId: string, pageSize: number, allUsers: boolean, isPLatinum: boolean, ids: DocumentReference[]): Observable<Process[]> {
    return (allUsers ?
      this._nextAllUsersProcessPageCollection(pageSize) :
      isPLatinum ? this._nextIsPlatinumProcessPageCollection(pageSize, ids) :
        this._nextProcessPageCollection(profileId, pageSize))
      .snapshotChanges()
      .pipe(
        take(1),
        map(events => {
          return events.filter(ev => {
            const swto = ev.payload.doc.data() as Process;
            if (ids && isPLatinum) {
              const idsR = ids.map(a => a.id);
              return idsR.includes(swto.profileId.id);
            } else return true;
          });
        }),
        tap(actions => {
          if (!actions[0]) { return; }
          this.firstInResponse = actions[0].payload.doc;
          this.lastInResponse = actions[actions.length - 1].payload.doc;
          this.paginationClickedCount++;
          this._pushPrevStartAt(this.firstInResponse);
        }),
        extractSnapshotDocumentsPipe(),
        switchMap(values => {
          values.map(async (value: Process) => {
            if (value.parentId) {
              const parent = (await this._firebaseStore.doc('processes/' + value.parentId).get().toPromise()).data() as Process;
              value.$canEdit = parent.versions.map(a => a.id).indexOf(value.id) === parent.versions.length - 1;
            }
            else value.$canEdit = true;
          });

          return of(values);
        }),
      ) as Observable<Process[]>;
  }

  private _nextIsPlatinumProcessPageCollection(pageSize: number, ids: DocumentReference[]): AngularFirestoreCollection {
    if (ids.length < 10) {
      return this._firebaseStore.collection(`processes`, ref => ref.where('profileId', 'in', ids)
        .limit(pageSize)
        .orderBy('creationDate', 'asc')
        .startAfter(this.lastInResponse)
      );
    } else {
      return this._firebaseStore.collection(`processes`, ref => ref //.where('profileId', 'in', ids)
        //.limit(pageSize)
        .orderBy('creationDate', 'asc')
        .startAfter(this.lastInResponse)
      );
    }
  }

  private _nextAllUsersProcessPageCollection(pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`processes`, ref => ref
      .limit(pageSize)
      .orderBy('creationDate', 'asc')
      .startAfter(this.lastInResponse)
    );
  }

  private _nextProcessPageCollection(profileId: string, pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`processes`, ref => ref
      .where('profileId', '==', this._firebaseStore.doc(`profiles/${profileId}`).ref)
      .limit(pageSize)
      .orderBy('creationDate', 'asc')
      .startAfter(this.lastInResponse)
    );
  }

  loadPreviousProcessPage(profileId: string, pageSize: number, allUsers: boolean, isPLatinum: boolean, ids: DocumentReference[]): Observable<Process[]> {
    return (allUsers ?
      this._previousAllUsersProcessPageCollection(pageSize) :
      isPLatinum ? this._previousIsPlatinumProcessPageCollection(pageSize, ids) :
        this._previousProcessPageCollection(profileId, pageSize))
      .snapshotChanges()
      .pipe(
        take(1),
        map(events => {
          return events.filter(ev => {
            const swto = ev.payload.doc.data() as Process;
            if (ids && isPLatinum) {
              const idsR = ids.map(a => a.id);
              return idsR.includes(swto.profileId.id);
            } else return true;
          });
        }),
        tap(actions => {
          this.firstInResponse = actions[0].payload.doc;
          this.lastInResponse = actions[actions.length - 1].payload.doc;
          this.paginationClickedCount--;
          // Pop not required value in array
          this._popPrevStartAt(this.firstInResponse);
        }),
        extractSnapshotDocumentsPipe(),
        switchMap(values => {
          values.map(async (value: Process) => {
            if (value.parentId) {
              const parent = (await this._firebaseStore.doc('processes/' + value.parentId).get().toPromise()).data() as Process;
              value.$canEdit = parent.versions.map(a => a.id).indexOf(value.id) === parent.versions.length - 1;
            }
            else value.$canEdit = true;
          });

          return of(values);
        }),
      ) as Observable<Process[]>;
  }

  private _previousIsPlatinumProcessPageCollection(pageSize: number, ids: DocumentReference[]): AngularFirestoreCollection {
    if (ids.length < 10) {
      return this._firebaseStore.collection(`processes`, ref => ref.where('profileId', 'in', ids)
        .orderBy('creationDate', 'asc')
        .startAt(this._getPrevStartAt())
        .endBefore(this.firstInResponse)
        .limit(pageSize)
      );
    } else {
      return this._firebaseStore.collection(`processes`, ref => ref //.where('profileId', 'in', ids)
        .orderBy('creationDate', 'asc')
        .startAt(this._getPrevStartAt())
        .endBefore(this.firstInResponse)
        //.limit(pageSize)
      );
    }
  }

  private _previousAllUsersProcessPageCollection(pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`processes`, ref => ref
      .orderBy('creationDate', 'asc')
      .startAt(this._getPrevStartAt())
      .endBefore(this.firstInResponse)
      .limit(pageSize)
    );
  }

  private _previousProcessPageCollection(profileId: string, pageSize: number): AngularFirestoreCollection {
    return this._firebaseStore.collection(`processes`, ref => ref
      .where('profileId', '==', this._firebaseStore.doc(`profiles/${profileId}`).ref)
      .orderBy('creationDate', 'asc')
      .startAt(this._getPrevStartAt())
      .endBefore(this.firstInResponse)
      .limit(pageSize)
    );
  }

  /**
   * Reset pagination
   */
  resetPagination(pageSize): void {
    this.lastInResponse = null;
    this.firstInResponse = null;
    this.pageSize = pageSize;
    this.paginationClickedCount = 0;
    this.prevStrtAt = [];
  }

  /**
   * Push previous startAt document
   *
   * @param prevFirstDoc
   */
  private _pushPrevStartAt(prevFirstDoc): void {
    if (!prevFirstDoc) { return; }
    if (this.prevStrtAt.map(el => el.id).indexOf(prevFirstDoc.id) === -1) {
      this.prevStrtAt.push(prevFirstDoc);
    }
  }

  /**
   * Pop previous startAt document
   *
   * @param prevFirstDoc
   */
  private _popPrevStartAt(prevFirstDoc): void {
    this.prevStrtAt.forEach(element => {
      if (prevFirstDoc.id === element.id) {
        element = null;
      }
    });
  }

  /**
   * Get previous startAt document
   *
   */
  private _getPrevStartAt(): any {
    if (this.prevStrtAt.length > (this.paginationClickedCount + 1)) {
      this.prevStrtAt.splice(this.prevStrtAt.length - 2, this.prevStrtAt.length - 1);
    }
    return this.prevStrtAt[this.paginationClickedCount - 1];
  }
}
