import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Timestamp } from '../interfaces/Timestamp';
import { TranslateService } from '@ngx-translate/core';


/**
 * 'date' pipe which supports firebase Timestamp
 */
@Pipe({name: 'localedate'})
export class LocaleDatePipe implements PipeTransform {
  private datePipe: DatePipe;

  constructor(
    private _translateService: TranslateService
  ) {
    this.datePipe = new DatePipe(this._translateService.currentLang);
  }

  transform(value: string | Date | Timestamp): any {
    if (value instanceof Timestamp) {
      value = value.toDate();
    }

    if (this._translateService.currentLang === 'en') {
      return this.datePipe.transform(value, 'yyyy/MM/dd hh:mm a');
    }

    return this.datePipe.transform(value, 'yyyy/MM/dd hh:mm');
  }
}
