import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteHelper {
  static readonly EMPTY_PATH = '';
  static readonly ROOT_PATH = '/';
  static readonly AUTH_PATH = 'auth';
  static readonly LOGIN_PATH = 'login';
  static readonly SIGN_UP_PATH = 'sign-up';
  static readonly RESTORE_PATH = 'restore';
  static readonly USER_ACTIONS = 'user-actions';
  static readonly CHANGE_PASSOWRD = 'change-password';
  static readonly CLIENT_PATH = 'client';
  static readonly LIST_PATH = 'list';
  static readonly HOME_PATH = 'home';
  static readonly DETAILS_PATH = 'details';
  static readonly DIAGRAM_PATH = 'diagram';
  static readonly DIAGRAM_VERTICAL = 'diagram-vertical';
  static readonly DIAGRAM_VERTICAL2 = 'diagram-vertical-2';
  static readonly SWOT_PATH = 'swots';
  static readonly SWOT_DETAILS_PATH = 'details';
  static readonly PROCESSES_PATH = 'processes';
  static readonly DASHBOARD_PATH = 'dashboard';
  static readonly ADMIN_PATH = 'admin';
  static readonly USER_PATH = 'user';
  static readonly PROFILE_PATH = 'profile';
  static readonly PRIVACY_PATH = 'privacy';
  static readonly CONDITION_OF_USE_PATH = 'condition-of-use';
  static readonly FAQ_PATH = 'faq';
  static readonly PRICES_PATH = 'prices';
  static readonly SUBSCRIPTION_PATH = 'subscription';
  static readonly PROCESS_HOW_TO = 'info';

  get authUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.AUTH_PATH
    ];
  }

  get signUpUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.AUTH_PATH,
      RouteHelper.SIGN_UP_PATH
    ];
  }

  get restoreUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.AUTH_PATH,
      RouteHelper.RESTORE_PATH
    ];
  }

  get changePasswordUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.CHANGE_PASSOWRD
    ];
  }

  get adminPartUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.ADMIN_PATH
    ];
  }

  get clientPartUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.CLIENT_PATH
    ];
  }

  get swotListUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.SWOT_PATH
    ];
  }

  get processListUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.PROCESSES_PATH
    ];
  }

  get processDiagramUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.PROCESSES_PATH,
      RouteHelper.DIAGRAM_PATH
    ];
  }

  get processDiagramVerticalUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.PROCESSES_PATH,
      RouteHelper.DIAGRAM_VERTICAL
    ];
  }

  get processDiagramVerticalUrl2(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.PROCESSES_PATH,
      RouteHelper.DIAGRAM_VERTICAL2
    ];
  }

  get homeUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.HOME_PATH,
    ];
  }

  get dashboardUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.HOME_PATH,
      RouteHelper.DASHBOARD_PATH,
    ];
  }

  get adminDashboardUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.HOME_PATH,
      RouteHelper.DASHBOARD_PATH,
      RouteHelper.ADMIN_PATH,
    ];
  }

  get userDashboardUrl(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.HOME_PATH,
      RouteHelper.DASHBOARD_PATH,
      RouteHelper.USER_PATH,
    ];
  }

  get swotListPath(): string {
    return RouteHelper.SWOT_PATH;
  }

  get swotDetailsPath(): string {
    return RouteHelper.SWOT_DETAILS_PATH;
  }

  get processListPath(): string {
    return RouteHelper.PROCESSES_PATH;
  }

  get privacyPath(): string {
    return RouteHelper.PRIVACY_PATH;
  }

  get conditionOfUsePath(): string {
    return RouteHelper.CONDITION_OF_USE_PATH;
  }

  get faqPath(): string {
    return RouteHelper.FAQ_PATH;
  }

  get pricesPath(): string {
    return RouteHelper.PRICES_PATH;
  }

  get adminPath(): string {
    return RouteHelper.ADMIN_PATH;
  }

  get userPath(): string {
    return RouteHelper.USER_PATH;
  }

  get subscriptionPath(): string {
    return RouteHelper.SUBSCRIPTION_PATH;
  }

  get processesHowTo(): string[] {
    return [
      RouteHelper.ROOT_PATH,
      RouteHelper.PROCESSES_PATH,
      RouteHelper.PROCESS_HOW_TO
    ];
  }
}
