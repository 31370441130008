import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteHelper } from '../helpers/route-helper';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import * as navigation from 'app/navigation/navigation';
import { ProsaRoleType } from 'app/shared';

const ROLE_CLIENT = 'client';
const ROLE_ADMIN = 'admin';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
    private _router: Router,
    private _routeHelper: RouteHelper,
    private _fuseNavigationService: FuseNavigationService,
  ) {
    // Register the navigation to the service
    this._fuseNavigationService.register(ROLE_CLIENT, navigation.getNavigation(ROLE_CLIENT));
    this._fuseNavigationService.register(ROLE_ADMIN, navigation.getNavigation(ROLE_ADMIN));
  }

  setDefaultNavigation = (): void => {
    this.setNavigation(ROLE_CLIENT);
  }

  setNavigation = (role: string): void => {
    if (role === ProsaRoleType.user) { role = ROLE_CLIENT; }
    this._fuseNavigationService.setCurrentNavigation(role);
  }

  navigateToAuth = (): void => {
    this._router.navigate(this._routeHelper.authUrl);
  }

  navigateToHome = (): void => {
    this._router.navigate(this._routeHelper.homeUrl);
  }

  navigateToAdminDashboard = (): void => {
    this._router.navigate(this._routeHelper.adminDashboardUrl);
  }

  navigateToUserDashboard = (): void => {
    this._router.navigate(this._routeHelper.userDashboardUrl);
  }

  navigateToSwots = (): void => {
    this._router.navigate(this._routeHelper.swotListUrl);
  }

  navigateToProcesses = (): void => {
    this._router.navigate(this._routeHelper.processListUrl);
  }

  navigateToSubscription = (): void => {
    this._router.navigate([this._routeHelper.subscriptionPath]);
  }

  navigateToPurchaseResult = (id: string): void => {
    this._router.navigate([this._routeHelper.subscriptionPath, id]);
  }
}
