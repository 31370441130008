// https://en.wikipedia.org/wiki/Binary_GCD_algorithm
export function gcd(a: number, b: number): number {
  if (a === b) {
    return a;
  }
  if (a === 0 && b === 0) {
    return 0;
  }
  if (a === 0 || b === 0) {
    return a || b;
  } else if (a > b) {
    return gcd(b, a - b);
  } else {
    return gcd(a, b - a);
  }
}


// some random uidentifier
export function uid(): string {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}
