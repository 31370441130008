import { Component, OnInit, Input, Output, EventEmitter,
  AfterViewInit, HostBinding, ViewChild, OnDestroy } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ProsaControl } from '../../prosa.control';
import { Subscription } from 'rxjs';

export interface SelectOption {
  value: number | string;
  name: string;
  color?: string;
  icon?: string;
}

const THEME_OUTLINE = 'outline';
const THEME_OUTLINE_LIGHT = 'outline-light';

@Component({
  selector: 'prosa-select',
  templateUrl: './select.component.html',
})
export class ProsaSelectComponent extends ProsaControl implements OnInit, AfterViewInit, OnDestroy {
  @Input() options: SelectOption[];
  @Input() placeholder?: string;
  @Input() theme?: string;

  @Output() selected: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatSelect, {static: false}) selectComponent;

  private _openedChangeSub: Subscription;
  private _opened = false;

  @HostBinding('class')
  class = 'prosa-select';

  @HostBinding('class.prosa-select-opened')
  get opened(): boolean {
    return !!this._opened;
  }
  set opened(state: boolean) {
    this._opened = state;
  }

  /**
   * THEME
   */
  @HostBinding('class.prosa-theme-outline')
  get isOutlineTheme(): boolean {
    return this.theme === THEME_OUTLINE;
  }

  @HostBinding('class.prosa-theme-outline-light')
  get isOutlineLightTheme(): boolean {
    return this.theme === THEME_OUTLINE_LIGHT;
  }

  ngAfterViewInit(): void {
    this._openedChangeSub = this.selectComponent.openedChange.subscribe((opened: boolean) => {
      this.opened = opened;
      if (this.opened) {
        this.selectComponent.panel.nativeElement.style.display = 'block';
      }
    });
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this._openedChangeSub.unsubscribe();
  }

  onSelectionChange(): void {
    this.selected.emit();
  }
}
