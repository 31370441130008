import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { ContentPathService } from './content-path.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ContentPath {
    layout: number;
    title: string;
    description?: string;
    backRoute?: string[];
    links: ContentPathLink[];
    isOtherUser?: boolean;
}

interface ContentPathLink {
    text: string;
    routes?: string[];
}

@Component({
    selector: 'content-path',
    templateUrl: './content-path.component.html',
    styleUrls: ['./content-path.component.scss']
})
export class ContentPathComponent implements OnInit, OnDestroy {
    configs: ContentPath;

    private _unsubscribeAll = new Subject();

    constructor(
        private _contentPathService: ContentPathService) {

        this._contentPathService.getConfigs().pipe(
            takeUntil(this._unsubscribeAll)
        ).subscribe((configs: ContentPath) => {
            this.configs = configs || {} as ContentPath;
        });
    }

    ngOnInit(): void { }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._unsubscribeAll = null;
    }
}
