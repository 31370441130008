import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[prosaDropzone]'
})
export class DropzoneDirective {
  @Input() prosaDropzoneImageOnly = false;
  @Output() dropped =  new EventEmitter<File[]>();
  @Output() hovered =  new EventEmitter<boolean>();

  @HostListener('drop', ['$event'])
  onDrop($event): void {
    $event.preventDefault();
    let files: File[] = this._extractFiles($event.dataTransfer.files);

    if (this.prosaDropzoneImageOnly) {
      files = files.filter(this._isImageType);
    }

    if (files.length > 0) {
      this.dropped.emit(files);
    }
    this.hovered.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event): void {
    $event.preventDefault();
    this.hovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event): void {
    $event.preventDefault();
    this.hovered.emit(false);
  }

  constructor() { }

  private _extractFiles(fileList: FileList): File[] {
    if (!fileList) { return []; }
    const files = [];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }
    return files;
  }

  private _isImageType(file: File): boolean {
    return file.type.indexOf('image') > -1;
  }
}
