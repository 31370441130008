import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'prosa-content-popup',
  templateUrl: './content-popup.component.html'
})
export class ContentPopupComponent implements OnInit {
  @Input() dialogRef: MatDialogRef<any>;
  @Input() popupTitle: string;
  @Input() confirmTitle = 'Salva';
  @Input() isValid: boolean;
  @Input() showButton = true;
  @Input() showSecondButton = false;
  @Input() showContent = true;
  @Input() readOnly = false;
  //@Input() icon: string;

  @Output() closed: EventEmitter<any> = new EventEmitter();
  //@Output() info: EventEmitter<any> = new EventEmitter();
  @Output() confirmed: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void { }

  /**
   * Close popup
   */
  onClose(): void {
    this.dialogRef.close();
    this.closed.emit();
  }

  // onInfo(): void {
  //   this.info.emit();
  // }

  onConfirm(): void {
    this.confirmed.emit();
  }

  onDelete() {
    this.delete.emit();
  }
}
