import { Component, OnInit } from '@angular/core';

const DEFAULT_LIMIT = 4;

@Component({
    selector: 'prosa-notifications-menu',
    templateUrl: './notifications-menu.component.html',
    styleUrls: ['./notifications-menu.component.scss']
})
export class NotificationsMenuComponent implements OnInit {

    limit = DEFAULT_LIMIT;
    expanded = false;

    // fake data
    notifications = [{
        text: 'Messaggio o notifica relativa alla  funzione numero uno',
        image: 'assets/images/avatars/profile.jpg',
        date: new Date()
    }, {
        text: 'Messaggio o notifica relativa alla funzione numero due',
        image: 'assets/images/avatars/profile.jpg',
        date: new Date()
    }, {
        text: 'Messaggio o notifica relativa alla funzione numero tre',
        image: 'assets/images/avatars/profile.jpg',
        date: new Date()
    }, {
        text: 'Messaggio o notifica relativa alla funzione numero due',
        image: 'assets/images/avatars/profile.jpg',
        date: new Date()
    }, {
        text: 'Messaggio o notifica relativa alla funzione numero tre',
        image: 'assets/images/avatars/profile.jpg',
        date: new Date()
    }, {
        text: 'Messaggio o notifica relativa alla funzione numero due',
        image: 'assets/images/avatars/profile.jpg',
        date: new Date()
    }, {
        text: 'Messaggio o notifica relativa alla funzione numero tre',
        image: 'assets/images/avatars/profile.jpg',
        date: new Date()
    }, {
        text: 'Messaggio o notifica relativa alla funzione numero due',
        image: 'assets/images/avatars/profile.jpg',
        date: new Date()
    }, {
        text: 'Messaggio o notifica relativa alla funzione numero tre',
        image: 'assets/images/avatars/profile.jpg',
        date: new Date()
    }, {
        text: 'Messaggio o notifica relativa alla funzione numero due',
        image: 'assets/images/avatars/profile.jpg',
        date: new Date()
    }];

    constructor() { }

    ngOnInit(): void { }

    onMoreClick(event): void {
        // Update expand state
        this.expanded = !this.expanded;
        // Update limit
        this.limit = this.expanded ? this.notifications.length : DEFAULT_LIMIT;
        // Stop close event propagation
        event.stopPropagation();
    }

    onMenuClosed(): void {
        if (this.expanded) {
            // Reset after end of 'close' animation
            setTimeout(() => {
                // Reset expand state
                this.expanded = false;
                // Reset limit
                this.limit = DEFAULT_LIMIT;
            }, 300);
        }
    }

    hasNotifications(): boolean {
        return this.notifications && this.notifications.length > 0;
    }
}
