import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { NavigationService } from '../../../services/navigation.service';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ProsaAuthService } from 'app/shared';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private _angularFireAuth: AngularFireAuth,
    private _navigationService: NavigationService,
    private _authService: ProsaAuthService,
  ) { }

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Load firebase user
    return this._angularFireAuth.user.pipe(
      switchMap(user => {
        // Redirect to login if not authorized
        if (!user || !user.emailVerified) {
          return this._navigateToAuth();
        }
        // Pre-load auth info
        return this._authService.resolve();
      }),
      // Check additional conditions
      map(_ => true),
      // Redirect to login if error
      catchError(this._navigateToAuth)
    );
  }

  private _navigateToAuth(): Observable<boolean> {
    this._navigationService.navigateToAuth();
    return of(false);
  }
}
