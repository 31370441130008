import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable()
export class ValidationErrorService {

    getControlError = (control: AbstractControl): string | null => {
        const { errors } = control;

        if (!errors) { return; }

        if (errors.required) {
            return '* Campo obbligatorio';
        } else if (errors.email) {
            return '* Email non valido';
        } else if (errors['password-not-equal']) {
            return '* La password non coincide';
        } else if (errors['password-invalid']) {
            return '* La password non è corretta';
        } else if (errors.pattern) {
            return '* Campo non valido';
        }
    }

}
