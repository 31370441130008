import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'prosa-swot-add',
    templateUrl: './new-user.component.html',
    styleUrls: ['./new-user.component.scss']
})
export class NewUserPopupComponent implements OnInit {

    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<NewUserPopupComponent>,
        private _formBuilder: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any) {

    }

    ngOnInit() {
        this.form = this._formBuilder.group({
            name: ['', Validators.required],
            surname: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]]
        });
    }

    onConfirm() {
        if (!this.form.valid) return;

        this.dialogRef.close({ email: this.form.value.email, name: this.form.value.name, surname: this.form.value.surname });
    }
}