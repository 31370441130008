import { ProsaInputComponent } from './input/input.component';
import { ProsaButtonComponent } from './button/button.component';
import { ProsaCheckboxComponent } from './checkbox/checkbox.component';
import { ProsaBaseComponent } from './base/base.component';
import { ProsaSelectComponent } from './select/select.component';
import { ProsaAutocompleteComponent } from './autocomplete/autocomplete/autocomplete.component';
import { ChipsAutocompleteComponent } from './autocomplete/chips/chips-autocomplete.component';
import { SliderComponent } from './slider/slider.component';

export const controls = [
  ProsaInputComponent,
  ProsaButtonComponent,
  ProsaCheckboxComponent,
  ProsaBaseComponent,
  ProsaSelectComponent,
  ProsaAutocompleteComponent,
  ChipsAutocompleteComponent,
  SliderComponent,
];
