export const locale = {
  lang: 'it',
  data: {
    // MODULE: SHARED
    sharedModule: {
      // COMPONENTS
      components: {
        // COMPONENT: PRINT REPORT
        printReport: {
          title: 'Stampa swot',
          content: {
            title: 'Selezionare il formato di stampa della SWOT',
            // tslint:disable-next-line:max-line-length
            desctiption: `Sono disponibili tre formati di stampa della SWOT selezionata.<br>
            La stampa sintetica prevede la stampa su foglio A4 delle quattro aree, con i valori per singola voce ed i grafici.<br><br>
            La stampa estesa e la stampa del piano di miglioramento <a href="/prices">(solo per il piano GOLD)</a>, 
            previste su foglio A3, estraggono nel dettaglio tutte le voci inserite.`,
          },
          options: {
            first: 'Sintetica',
            second: 'Estesa',
            third: 'Piano miglioram.'
          },
        },
        printProcess: {
          title: 'Stampa processo',
          content: {
            title: 'Selezionare il formato di stampa del processo',
            // tslint:disable-next-line:max-line-length
            desctiption: `Sono disponibili due formati di stampa del processo selezionato.<br>
            La stampa sintetica prevede la stampa su foglio A4 delle quattro aree, con i valori per singola voce ed i grafici.<br><br>
            La stampa estesa <a href="/prices">(solo per il piano GOLD)</a>, prevista su foglio A3, estrae nel dettaglio tutte le voci inserite.
            `,
          },
          options: {
            first: 'Sintetica',
            second: 'Estesa'
          },
        },
        // COMPONENT: CONFIRM DELETE
        confirmDelete: {
          title: 'ELIMINA RECORD',
          description: 'Vuoi cancellare questo record?',
          actions: {
            cancel: 'Annulla',
            delete: 'Cancella',
          },
          subDescPhase: 'E tutte le eventuali sotto fasi',
          subDescSubPhase: 'E tutte le eventuali attività'
        },
        // COMPONENT: CHANGE PASSWORD
        changePassword: {
          title: 'Cambio password!',
          description: 'Inserisci la tua attuale password ed inserisci la nuova',
          currentPassword: 'Password Attuale',
          newPassword: 'Nuova Password',
          confirmPassword: 'Ripeti Password',
          confirm: 'SALVA',
        },
        // COMPONENT: CONTENT POPUP
        contentPopup: {
          confirm: 'SALVA',
        },
        // COMPONENT: EMAIL NOT VERIFIED
        emailNotVerfied: {
          title: 'Conferma registrazione',
          subTitle: 'Attenzione è stata inviata una mail per la conferma \ndella registrazione',
          desc: 'Verificare la propria casella di posta e utilizzare il link \nriportato per confermare la propria registrazione.',
          resendButton: 'Invia nuova Email',
          defaultButton: 'Continua'
        },
        // COMPONENT: EMAIL NOT VERIFIED
        tableTransferUser: {
          name: 'NOMINATIVO',
          email: 'EMAIL',
          subscription: 'PROFILO',
        }
      },
    },
  }
};
