import { NgModule } from '@angular/core';
import { MaterialModule } from '../material/material.module';
import { CommonModule } from '@angular/common';
import { controls } from './components/controls';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationErrorService } from './services/validation-error.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MaterialModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
    ],
    declarations: [
        ...controls,
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        ...controls
    ],
    providers: [
        ValidationErrorService
    ]
})
export class ProsaFormsModule {

}
