import { Timestamp } from './Timestamp';
import { ISubscriptionPurchase } from 'app/modules/pages/subscription/interfaces/subscription-purchase.interface';
import { DocumentReference } from '@angular/fire/firestore';


export interface ProsaUserProfile {
    uid?: string;
    id: string;
    name: string;
    surname: string;
    registrationDate: Timestamp;
    expirationDate: Timestamp;
    roleType: ProsaRoleType;
    subscriptionType: ProsaSubscriptionType;
    paymentTransaction?: ProsaPaymentTransaction[];
    subsciptionPurchaseReference?: ISubscriptionPurchase[];
    email: string;
    photo: {
        url?: string;
        storagePath?: string;
    };
    disabled: boolean;
    conversionPlanToGoldDate?: Timestamp[];
    conversionPlanToPlatinumDate?: Timestamp[];
    assignedUsers?: DocumentReference[];
    $realAssignedUsers?: ProsaUserProfile[];
    numberOfAssignedUsers: number;
    owner?: DocumentReference;
    hasLoggedIn: boolean;
}

export enum ProsaRoleType {
    admin = 'admin',
    user = 'user',
    guest = 'guest'
}

export enum ProsaSubscriptionType {
    silver = 'silver',
    gold = 'gold',
    trial = 'trial',
    platinum = 'platinum'
}

export interface ProsaPaymentTransaction {
    transactionDate: Timestamp;
}

export const subscriptionTypes = {
    silver: ProsaSubscriptionType.silver,
    gold: ProsaSubscriptionType.gold,
    trial: ProsaSubscriptionType.trial,
    platinum: ProsaSubscriptionType.platinum
};
