import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ProsaErrorService } from '../services/error.service';



@Injectable()
export class ProsaErrorHandler implements ErrorHandler {
  private _errorService: ProsaErrorService;

  // injecting dependency directly cause circular dependency
  // https://stackoverflow.com/a/41585902/1269984
  constructor(injector: Injector) {
    setTimeout(() => this._errorService = injector.get(ProsaErrorService));
  }

  handleError(err: any): void {
    // do something with the error
    console.error(err);
    // err.options = err.options || {};
    // err.options.actionUrl = err.options.actionUrl || '/';
    this._errorService.emitError(err);
  }
}
