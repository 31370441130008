import { Component, OnInit, Input, Output, EventEmitter, HostBinding, ViewChild } from '@angular/core';
import { ProsaControl } from '../../../prosa.control';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';

export interface SelectOption {
  value: number | string;
  name: string;
  color?: string;
  icon?: string;
}

const THEME_OUTLINE = 'outline';
const THEME_OUTLINE_LIGHT = 'outline-light';

@Component({
  selector: 'prosa-autocomplete',
  templateUrl: './autocomplete.component.html',
})
export class ProsaAutocompleteComponent extends ProsaControl implements OnInit {
  @Input() options: SelectOption[];
  @Input() placeholder?: string;
  @Input() theme?: string;
  @Input() disabled?: boolean;

  @Output() selected: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatAutocomplete, {static: false}) autocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, {static: false}) autocompleteTrigger: MatAutocompleteTrigger;

  @HostBinding('class')
  class = 'prosa-autocomplete';

  @HostBinding('class.prosa-select-opened')
  get isOpened(): boolean {
    return !!this.autocomplete && this.autocomplete.isOpen;
  }

  @HostBinding('class.prosa-select__disabled')
  get isDisabled(): boolean {
    return this.disabled;
  }

  /**
   * THEME
   */
  @HostBinding('class.prosa-theme-outline')
  get isOutlineTheme(): boolean {
    return this.theme === THEME_OUTLINE;
  }

  @HostBinding('class.prosa-theme-outline-light')
  get isOutlineLightTheme(): boolean {
    return this.theme === THEME_OUTLINE_LIGHT;
  }

  ngOnInit(): void { }

  getOptionName(value: string | number): string {
    const option: SelectOption = this.options.find((item: SelectOption) => {
      return item.value === value;
    });
    return option ? option.name : '';
  }

  onSelectionChange(): void {
    this.selected.emit();
  }

  toggle(): void {
    this.autocomplete.isOpen || this.disabled ? this.autocompleteTrigger.closePanel()
      : this.autocompleteTrigger.openPanel();
  }

  isSelected(option: SelectOption): boolean {
    return option.value === this.control.value;
  }
}
