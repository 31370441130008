import { Injectable } from '@angular/core';
import { ContentPath } from 'app/layout/components/content/content-path/content-path.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ContentPathService {

    configs: ContentPath | null;
    onConfigsChanged: BehaviorSubject<ContentPath>;

    constructor() {
        this.configs = null;
        this.onConfigsChanged = new BehaviorSubject({} as ContentPath);
    }

    getConfigs(): Observable<ContentPath> {
        return this.onConfigsChanged;
    }

    update(configs: any): void {
        this.configs = this.configs || {} as ContentPath;
        Object.assign(this.configs, configs);
        this.onConfigsChanged.next(this.configs);
    }

    reset(): void {
        this.configs = null;
        this.onConfigsChanged.next(this.configs);
    }

    isHidden(): boolean {
        return !this.configs;
    }
}
