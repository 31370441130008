export const locale = {
  lang: 'it',
  data: {
    NAV: {
      HOME: 'Home',
      FUNCTIONS: 'Funzioni',
      SWOT_LIST: 'Lista SWOT',
      PROCESS_LIST: 'Lista processi',
    }
  }
};
