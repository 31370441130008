import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { extractQuerySnapshotPipe } from 'app/shared/utils';
import { take, catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SWOT, ProsaError, SwotVoice, SwotAspectType } from 'app/shared';

@Injectable()
export class SwotVoiceProvider {

    /**
     * Constructor
     *
     * @param {AngularFirestore} _firebaseStore
     */
    constructor(
        private _firebaseStore: AngularFirestore
    ) {}

    /**
     * Get swot voices
     * 
     * @param {SWOT} swot
     * 
     * @returns {Promise<SwotVoice[]>} 
     */
    async getSwotVoices(swot: SWOT): Promise<SwotVoice[]> {
        const voices: SwotVoice[] = await this._firebaseStore.doc(`swots/${swot.id}`).collection(`voices`)
        .snapshotChanges()
        .pipe(
            take(1),
            map((actions: []) => actions.map((act: any) => {
                const data = act.payload.doc.data();

                return {
                    ...data
                };
            }))
        ).toPromise();

        return voices;
    }

    getVoicesByAspect(swotId: string, aspectType: SwotAspectType): Observable<SwotVoice[]> {
        return this._firebaseStore.doc(`swots/${swotId}`).collection(`voices`, ref => ref
            .where('aspectType', '==', aspectType)
            .orderBy('creationDate', 'asc')
        ).get().pipe(
            extractQuerySnapshotPipe(),
            catchError(err => {
                console.error(err);
                return of([]);
            })
        ) as Observable<SwotVoice[]>;
    }

    /**
     * Add voice
     * 
     * @param {string} swotId
     * @param {SwotVoice} voice
     */
    async addVoice(swotId: string, voice: SwotVoice) {
        try {
            const result = await this._firebaseStore.doc(`swots/${swotId}`).collection(`voices`).add(voice);
            return result;     
        } catch (error) {
            throw new ProsaError(error);
        }
    }

    /**
     * Edit voice
     * 
     * @param {string} swotId 
     * @param {string} voiceId 
     * @param {SwotVoice} voice 
     */
    async editVoice(swotId, voiceId, voice) {
        try {
            const result = await this._firebaseStore.doc(`swots/${swotId}/voices/${voiceId}`).update(voice);
            return result;
        } catch (error) {
            throw new ProsaError(error);
        }
    }

    /**
     * Delete voice
     * 
     * @param {string} swotId 
     * @param {string} voiceId 
     */
    async deleteVoice(swotId, voiceId) {
        try {
            const result = await this._firebaseStore.doc(`swots/${swotId}/voices/${voiceId}`).delete();
            return result;
        } catch (error) {
            throw new ProsaError(error);
        }
    }

    /**
     * Duplicate voices subcollection. When we want to duplicate swot we should duplicate it with its voices subcollection
     * 
     * @param {DocumentReference} swotDocRef 
     * @param {SwotVoice[]} voices 
     * 
     * @returns {Promise<void>}
     */
    async duplicateVoices(swotDocRef: DocumentReference, voices: SwotVoice[]): Promise<void> {
        const batch = this._firebaseStore.firestore.batch();

        voices.forEach(voice => {
            let voiceDocRef = swotDocRef.collection(`voices`).doc(this._firebaseStore.createId());
            batch.set(voiceDocRef, voice);
        });

        await batch.commit();
    }

}