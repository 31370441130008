import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { UsersListService } from 'app/modules/pages/home/dashboard/admin/services/users-list.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'prosa-swot-add',
    templateUrl: './transfer.component.html',
    styleUrls: ['./transfer.component.scss']
})
export class SwotTransferPopupComponent implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;

    @ViewChild(MatPaginator, { static: false })
    paginator: MatPaginator;

    displayedColumns = ['select', 'name', 'email', 'subscriptionType'];
    dataSource = new MatTableDataSource([]);

    selectedProfile: any;

    constructor(
        public dialogRef: MatDialogRef<SwotTransferPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data,
        private _usersListService: UsersListService) {

        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this._usersListService.getUsersForPopUp();
        this._usersListService.onUsersChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            if (res){
                this.dataSource.data = res;
                this.dataSource.paginator = this.paginator;
            }
        });   
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._unsubscribeAll = null;
    }

    selectProfile(profile: any) {
        this.selectedProfile = profile;
    }

    onConfirm() {
        this.dialogRef.close(this.selectedProfile);
    }

}