import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { NotificationsMenuComponent } from './notifications-menu/notifications-menu.component';

import { TranslateModule } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';

@NgModule({
    declarations: [
      ToolbarComponent,
      NotificationsMenuComponent
    ],
    imports: [
      RouterModule,
      MatButtonModule,
      MatIconModule,
      MatMenuModule,
      MatToolbarModule,

      FuseSharedModule,
      FuseSearchBarModule,
      FuseShortcutsModule,

      TranslateModule,
    ],
    exports: [
      ToolbarComponent
    ]
})
export class ToolbarModule {
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, italian);
  }
}
