import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnlineStatusService {

  get timestamp(): object {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  get onlineStatus(): string {
    return 'online';
  }

  get offlineStatus(): string {
    return 'offline';
  }

  get user(): Promise<any> {
    return this._angularFireAuth.authState.pipe(first()).toPromise();
  }

  constructor(
    private _angularFireAuth: AngularFireAuth,
    private _angularFireDatabase: AngularFireDatabase,
  ) {
    // Check connection status
    const connection = this._angularFireDatabase.object('.info/connected')
      .valueChanges().pipe(
        map(connected => connected ? this.onlineStatus : this.offlineStatus)
      );
    // Update connection status
    this._angularFireAuth.authState.pipe(
      switchMap(user =>  user ? connection : of(this.offlineStatus)),
      tap(status => this.updateStatus(status))
    ).subscribe();
    // Update status afted disconnect
    this._angularFireAuth.authState.pipe(tap(user => {
      if (user) {
        this._angularFireDatabase.object(`status/${user.uid}`).query.ref.onDisconnect()
          .update(this._getStatusObj(this.offlineStatus));
      }
    })).subscribe();
  }

  async updateStatus(status: string): Promise<any> {
    const user = await this.user;
    if (user) {
      return this._angularFireDatabase.object(`status/${user.uid}`)
        .update(this._getStatusObj(status));
    }
  }

  getUserStatus(filterCallback = null): Observable<any> {
    return this._angularFireDatabase.object(`status`).valueChanges()
      .pipe(switchMap((state: object) => {
        if (!filterCallback) { return of(state); }
        // Return filtered state
        const result = {};
        for (const key in state) {
          if (filterCallback(key)) {
            result[key] = state[key];
          }
        }
        return of(result);
      }));
  }

  private _getStatusObj(status: string): object {
    return { status: status, timestamp: this.timestamp };
  }
}
