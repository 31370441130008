export const locale = {
  lang: 'it',
  data: {
    // MODULE: FOOTER
    footerModule: {
      prices: 'Prezzi',
      privacy: 'Privacy',
      conditionOfUse: 'Condizioni d\'Uso',
    },
  }
};
