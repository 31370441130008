export const DAY_MS = 24 * 60 * 60 * 1000;
export const HOUR_MS = 60 * 60 * 1000;
export const MIN_MS = 60 * 1000;


export function isSameDay(date1: Date, date2: Date): boolean {
  return getDaySeq(date1) === getDaySeq(date2);
}

/**
 * Get day index from the beginning on timestamp Jan 1, 1970.
 * It's not useful itself, but can be used for comparing two dates
 * Local time used (i.e. can be "yesterday" by UTC)
 * @param date
 */
export function getDaySeq(date: Date): number {
  return Math.floor((date.getTime() - date.getTimezoneOffset() * MIN_MS ) / DAY_MS);
}


/**
 * return 0h 0m 0s 0ms for the day
 * Local time used (i.e. can be "yesterday" by UTC)
 * @param date
 */
export function getDayBeginning(date: Date | number): Date {
  const timestamp = typeof date === 'number' ? date : date.getTime();
  const result = new Date(timestamp);
  result.setHours(0);
  result.setMinutes(0);
  result.setMilliseconds(0);
  return result;
}
