import { ProfileService, ProsaErrorService, ProsaAuthService, SwotProvider, SwotVoiceProvider, SwotTemplateProvider } from './services';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProsaFormsModule } from 'app/modules/forms/prosa-forms.module';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { TranslateModule } from '@ngx-translate/core';
import { ProsaErrorPopoverComponent, ProsaErrorMailNotVerifiedComponent } from './components';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AngularFireModule } from '@angular/fire';
import { firebaseConfig } from 'configs/firebaseConfig';
import { ReversePipe } from './pipes/reverse.pipe';
import { MatIconModule } from '@angular/material';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { LocaleDatePipe } from './pipes/format-date.pipe';
import { ProcessProvider } from './services/data-providers/process/process';
import { UserProvider } from './services/data-providers/user/user';
import { OnlineStatusService } from './services/online-status.service';

import { ContentPopupComponent } from './components/popups/base/content-popup/content-popup.component';
import { ConfirmPopupComponent } from './components/popups/base/confirm-popup/confirm-popup.component';
import { ConfirmationDeletePopupComponent } from './components/popups/confirmation-delete/confirmation-delete.component';
import { PrintReportPopupComponent } from './components/popups/print-report/print-report.component';
import { ChangePasswordPopupComponent } from './components/popups/change-password/change-password.component';
import { DropzoneDirective } from './directives/dropzone.directive';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';
import { SubscriptionService } from './services/subscription.service';
import { PrintProcessPopupComponent } from './components/popups/print-process/print-process.component';
import { SubscriptionPurchaseProvider } from './services/data-providers/subscription-purchase/subscription-purchase';
import { SwotTransferPopupComponent } from './components/popups/transfer-swot/transfer-swot.component';
import { UsersListService } from 'app/modules/pages/home/dashboard/admin/services/users-list.service';
import { MaterialModule } from 'app/modules/material/material.module';
import { NewUserPopupComponent } from './components/popups/new-user/new-user.component';


const sharedExports = [
  ProsaErrorPopoverComponent,
  ReversePipe,
  LocaleDatePipe,
  ContentPopupComponent,
  ConfirmPopupComponent,
  ConfirmationDeletePopupComponent,
  PrintReportPopupComponent,
  ChangePasswordPopupComponent,
  DropzoneDirective,
  PrintProcessPopupComponent,
  ProsaErrorMailNotVerifiedComponent,
  SwotTransferPopupComponent,
  NewUserPopupComponent
];

@NgModule({
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    ProsaFormsModule,
    MaterialModule
  ],
  entryComponents: [
    ProsaErrorPopoverComponent,

    ContentPopupComponent,
    ConfirmPopupComponent,

    ConfirmationDeletePopupComponent,
    PrintReportPopupComponent,
    ChangePasswordPopupComponent,
    PrintProcessPopupComponent,
    ProsaErrorMailNotVerifiedComponent,
    SwotTransferPopupComponent,
    NewUserPopupComponent
  ],
  declarations: sharedExports,
  exports: sharedExports
})
export class ProsaSharedModule {

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, italian);
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ProsaSharedModule,
      providers: [
        ProfileService,
        ProsaErrorService,
        ProsaAuthService,
        SwotProvider,
        ProcessProvider,
        SwotVoiceProvider,
        SwotTemplateProvider,
        UserProvider,
        OnlineStatusService,
        SubscriptionService,
        SubscriptionPurchaseProvider,
        UsersListService
      ],
    };
  }
}
