import { Component, OnInit, OnChanges, HostBinding, Input, HostListener, SimpleChanges } from '@angular/core';
import { ProsaControl } from '../../prosa.control';

@Component({
    selector: 'prosa-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class ProsaCheckboxComponent extends ProsaControl implements OnInit, OnChanges {
    @Input() label: string;
    @Input() disableRipple = true;
    @Input() checked?: boolean;
    @Input() rounded?: boolean;
    @Input() iconMode?: boolean;
    @Input() disabled?: boolean;
    @Input() description?: string;

    @HostBinding('class')
    class = 'prosa-checkbox';

    @HostBinding('class.prosa-checkbox-rounded')
    get isRounded(): boolean {
        return this.rounded;
    }
    @HostBinding('class.prosa-checkbox-icon-mode')
    get isIconMode(): boolean {
        return this.iconMode;
    }

    @HostListener('click', ['$event'])
    onClick(event): any {
        const target = event.target;

        if (!!target && target.tagName.toLowerCase() === 'a') {
            window.open(target.href, '_blank');
            return false;
        }
    }

    ngOnInit(): void {}

    ngOnChanges(simpleChanges: SimpleChanges): void {
        if (simpleChanges.disabled) {
            !!simpleChanges.disabled.currentValue ? this.control.disable() : this.control.enable();
        }
        if (simpleChanges.checked) {
            this.control.setValue(!!simpleChanges.checked.currentValue);
        }
    }
}
