import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'prosa-confirmation-delete',
  templateUrl: './confirmation-delete.component.html',
})
export class ConfirmationDeletePopupComponent implements OnInit {
  /**
   * Constructor
   *
   * @param {MatDialogRef<ConfirmationDeletePopupComponent>} dialogRef
   *
   */

  seeSubTitle = false;
  isPhase = false;
  isSubPhase = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDeletePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.seeSubTitle = data && data.seeSubTitle ? data.seeSubTitle : false;
    this.isPhase = data && data.isPhase ? data.isPhase : false;
    this.isSubPhase = data && data.isSubPhase ? data.isSubPhase : false;
  }

  ngOnInit(): void { }

  onClose(payload: string): void {
    this.dialogRef.close(payload);
  }

  onSelect(option: string): void {
    this.onClose(option === 'second' ? 'delete' : '');
  }
}
