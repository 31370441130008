import { Component, OnInit, Input, Output, EventEmitter, ViewChild, HostBinding, ElementRef } from '@angular/core';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material';
import { FormGroup } from '@angular/forms';

export interface SelectOption {
  value: number | string;
  name: string;
  color?: string;
  icon?: string;
}

const THEME_OUTLINE = 'outline';
const THEME_OUTLINE_LIGHT = 'outline-light';

@Component({
  selector: 'prosa-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
})
export class ChipsAutocompleteComponent implements OnInit {
  @Input() options: SelectOption[];
  @Input() control: FormGroup;
  @Input() placeholder?: string;
  @Input() theme?: string;

  @Output() selected: EventEmitter<any> = new EventEmitter();

  @ViewChild(MatAutocomplete, {static: false}) autocomplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, {static: false}) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('input', {static: false}) inputElement: ElementRef;

  @HostBinding('class')
  class = 'prosa-chips-autocomplete';

  @HostBinding('class.prosa-select-opened')
  get isOpened(): boolean {
    return !!this.autocomplete && this.autocomplete.isOpen;
  }

  /**
   * THEME
   */
  @HostBinding('class.prosa-theme-outline')
  get isOutlineTheme(): boolean {
    return this.theme === THEME_OUTLINE;
  }

  @HostBinding('class.prosa-theme-outline-light')
  get isOutlineLightTheme(): boolean {
    return this.theme === THEME_OUTLINE_LIGHT;
  }

  get controlOptions(): any[] {
    return this.control.controls[0].value.options || [];
  }

  constructor() { }

  ngOnInit(): void { }

  getOptionName(value: string | number): string {
    const option: SelectOption = this.options.find((item: SelectOption) => {
      return item.value === value;
    });
    return option ? option.name : '';
  }

  onSelectionChange(): void {
    this.selected.emit();
  }

  toggle(): void {
    this.autocomplete.isOpen ? this.autocompleteTrigger.closePanel()
      : this.autocompleteTrigger.openPanel();
  }

  isSelected(option: SelectOption): boolean {
    return this._findIndex(this.controlOptions, option) > -1;
  }

  add(selectedOption: SelectOption): void {
    const optionValues = this.controlOptions;
    if (selectedOption && this._findIndex(optionValues, selectedOption) === -1) {
      const controlFormGroup = this.control.controls[0] as FormGroup;
      optionValues.push(selectedOption);
      controlFormGroup.controls.options.setValue(optionValues);
    }
    this.inputElement.nativeElement.blur();
  }

  remove(selectedOption: SelectOption, event: MouseEvent): void {
    const controlFormGroup = this.control.controls[0] as FormGroup;
    const optionValues = this.controlOptions;
    if (optionValues.length > 1) {
      const index = this._findIndex(optionValues, selectedOption);
      if (index > -1) {
        optionValues.splice(index, 1);
        controlFormGroup.controls.options.setValue(optionValues);
      }
    } else if (optionValues.length === 1) {
      controlFormGroup.controls.options.setValue(null);
    }
  }

  private _findIndex(values, target): number {
    return values.findIndex(item => item.value === target.value);
  }
}
